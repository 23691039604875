import { FormGroupProps } from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import { SwitchProps } from '@material-ui/core/Switch';
import {
    CreateNode,
    EditNode, NodeActions, NodeActionsProps,SimpleForm, TreeWithDetails
} from '@react-admin/ra-tree-project-assets';
import { Record as RaRecord } from 'ra-core';
import React, { useState } from 'react';
import {
    AutocompleteInput, BooleanInput, EditProps, FormDataConsumer, InputProps, ListProps, RadioButtonGroupInput, ReferenceInput,
    required, ResourceComponentProps, TextField, TextInput, useDataProvider
} from 'react-admin';
import { useForm } from 'react-final-form';
import withMeta from '../components/withMeta';

// export されていないから再宣言 todo: pull request
type BooleanInputProps = InputProps<SwitchProps> &
    Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

const SimpleFormWithMeta = withMeta(SimpleForm, (record: any) => {
    const metadata = record.metadata;
    record.preload = metadata.preload;
    record.isShown = metadata.isShown;
});

const transform = async (data: RaRecord) => {
    if (data.type === "asset") {

        data.metadata.preload = data.preload;
        data.metadata.isShown = data.isShown;

        delete data.preload;
        delete data.isShown;
    }
    return data;
};

const useStyles = makeStyles({
    name: { display: 'inline-block' },
    booleanGroup: {
        "& label": {
            "margin-right": "auto"
        }
    },
    meta: {
        maxWidth: '40em',
    },
    toolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },

    //.MuiAccordion-root.Mui-expanded { margin: 16px 0; }
    accordionRoot: {
        "&.MuiAccordion-root.Mui-expanded": {
            margin: 0,
            // background: 'yellow'
        }
    },

    cardTree: {
        // backgroundColor: 'yellow',
        marginBottom: '1em'
    },

    treeDetailCard: {
        width: '100%'
    }

});

const PreloadInput: React.FC<BooleanInputProps> = props => {
    const form = useForm();
    const preloadOnchange = (preload: boolean) => {
        if (!preload) {
            form.change('isShown', false);
        }
    }

    return (
        <BooleanInput {...props} onChange={preloadOnchange} />
    );
}

const IsShownInput: React.FC<BooleanInputProps> = props => {
    const form = useForm();
    const isShownOnChange = (isShown: boolean) => {
        if (isShown) {
            form.change('preload', true);
        }
    }

    return (
        <BooleanInput {...props} onChange={isShownOnChange} />
    );
}

// a Create view for a tree uses <CreateNode> instead of the standard <Create>
const AssetsCreate = (props: ResourceComponentProps & ProjectNameProps) => {
    console.log('AssetsCreate', props);
    const project_id = (props.match?.params as any).id;
    const classes = useStyles();
    const { ...propsClone } = props as any;
    // これをしないと、project_id が projects_assets.id になる。
    // TODO: 他の項目も初期値にならないかチェックすること。
    // delete propsClone.record.id;
    propsClone.record = { metadata: {} };

    return (
        <CreateNode transform={transform} title={`${props.projectName} に追加`} {...propsClone} className={classes.treeDetailCard} >
            <SimpleFormWithMeta initialValues={{ project_id, type: 'asset' }} >
                <RadioButtonGroupInput source="type" choices={[
                    { id: 'asset', name: 'asset' },
                    { id: 'group', name: 'group' },
                ]} />
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => {
                        switch (formData.type) {
                            case "group":
                                return (<TextInput source="name" validate={[required()]} />);
                            case "asset":
                                return (<>
                                    <ReferenceInput source="asset_id" reference='assets' validate={[required()]}
                                        enableGetChoices={(filters) => (filters.q && filters.q.length >= 2)}
                                        filterToQuery={(q) => ({ name: q })}>
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    <TextInput source="alias_name" />
                                    <PreloadInput label="preload" source="preload" className={classes.booleanGroup} />
                                    <IsShownInput label="isShown" source="isShown" className={classes.booleanGroup} />
                                </>);
                        }
                    }}
                </FormDataConsumer>
            </SimpleFormWithMeta>
        </CreateNode>
    )
};

const AssetTitle = ({ projectName, record }: { projectName: string, record: any }) => {
    return <span>{`${projectName}`} {record ? `- ${record.name}` : ''}</span>;
};

type ProjectNameProps = {
    projectName: string
}

// an Edit view for a tree uses <EditNode> instead of the standard <Edit>
const AssetsEdit = (props: EditProps & ProjectNameProps) => {
    const classes = useStyles();
    return (
        <EditNode {...props} className={classes.treeDetailCard} transform={transform} title={<AssetTitle projectName={props.projectName} record={undefined} />}>
            <SimpleFormWithMeta>
                <TextField source="type" />
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => {
                        switch (formData.type) {
                            case "group":
                                return (<TextInput source="name" validate={[required()]} />);
                            case "asset":
                                return (<>
                                    <ReferenceInput source="asset_id" reference='assets' validate={[required()]}
                                        enableGetChoices={(filters) => (filters.q && filters.q.length >= 2)}
                                        filterToQuery={(q) => ({ name: q })}>
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    <TextInput source="alias_name" />
                                    <PreloadInput label="preload" source="preload" className={classes.booleanGroup} />
                                    <IsShownInput label="isShown" source="isShown" className={classes.booleanGroup} />
                                </>);
                        }
                    }}
                </FormDataConsumer>
            </SimpleFormWithMeta>
        </EditNode>
    )
};

const MyActions = (props: NodeActionsProps) => (
    <NodeActions {...props}>
        {/* <MyCustomActionMenuItem />
        <DeleteMenuItem /> */}
    </NodeActions>
);

// a List view for a tree uses <TreeWithDetails>
export const AssetsTree = (props: ListProps) => {
    const [projectName, setProjectName] = useState(" (Assets)");
    const dataProvider = useDataProvider();

    // console.log(JSON.stringify(props), JSON.stringify(props.match?.params));
    const project_id = (props.match?.params as any).id;

    // useEffect(() => {
    //     (async () => {
    //         const pn = await dataProvider.getOne('projects', { id: project_id }).then(response => response.data.name);
    //         setProjectName(pn);
    //     })()
    // }, []);

    // props の route 関係を projects から projects_assets に
    // params をかえてみようか
    // const 
    const classes = useStyles();

    const { resource, basePath, id, ...rest } = props as any;
    return (<TreeWithDetails
        className={classes.cardTree}
        resource='projects_assets'
        basePath={`/projects/${project_id}/assets`}
        title={projectName}
        projectName={projectName}
        titleField='name'
        create={AssetsCreate}
        edit={AssetsEdit}
        allowMultipleRoots
        draggable
        showLine
        nodeActions={<MyActions />}
        allowDrop={({ dragNode, dropNode, dropPosition }: { dragNode: any, dropNode: any, dropPosition: any }) => {
            console.log('allowDrop', dropNode);
            return (dropNode.type === "group");
        }}
        {...rest}
    />);
};  