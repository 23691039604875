import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// console.log(process.env.REACT_APP_KEYCLOAK_FRONTEND_URL);

const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_FRONTEND_URL,
  realm: 'tomap',
  clientId: 'tomap-storage'
});
export default keycloak;