// in src/Dashboard.js
import React, { useContext, useEffect, SyntheticEvent, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// <Title title="resources.dashboard.title" />
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LandscapeIcon from "@material-ui/icons/Landscape";

// import Title, { TitlePropType } from '../layout/Title';

import FreeSpaceChart from "../components/FreeSpaceChart";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Client } from "minio";
import { useKeycloak } from "@react-keycloak/web";
import { filter } from "lodash";

const Dashboard = (props: any) => {
  const { keycloak } = useKeycloak();
  const [buckets, setBuckets] = useState<any>([]);

  // 空き容量を SSO を待って確認するための処理
  // useEffect(() => {
  //   const handler = (e: MessageEvent) => {
  //     console.log("hogehoge1", e);
  //     if (e.data == "ssoDone") {
  //       console.log("hogehoge", process.env.REACT_APP_MINIO_API_ENDPOINT);
  //       if (buckets.length === 0) {
  //         fetch(`${process.env.REACT_APP_MINIO_API_ENDPOINT}/buckets`, {
  //           credentials: "include",
  //         })
  //           .then((res) => {
  //             return res.json();
  //           })
  //           .then((dataBuckets) => {
  //             // console.log('redirect', dataBuckets);
  //             // 各種処理が完了してから
  //             // if (!dataBuckets.code && dataBuckets.buckets) {
  //             //     setBuckets(dataBuckets.buckets);
  //             // }
  //             // TODO: create bucket は auth と postgres のトリガーなどでもよいかもしれない
  //             // (Dashboard が開かれ、かつここまで処理が継続されることは保証されていない)
  //             if (keycloak && keycloak.tokenParsed) {
  //               if (
  //                 dataBuckets.buckets === null ||
  //                 !dataBuckets.buckets.some(
  //                   (b: any) => b.name === `u-${keycloak!.tokenParsed!.sub}`
  //                 )
  //               ) {
  //                 fetch(`${process.env.REACT_APP_MINIO_API_ENDPOINT}/buckets`, {
  //                   method: "POST",
  //                   credentials: "include",
  //                   // preflight で OPTIONS に Cookie がつかず CORS 的な事象が発生する。
  //                   // preflight を 抑制するために bud hack だが caddy で content-type を差し替える
  //                   // headers: {
  //                   //     'Content-Type': 'application/json'
  //                   // },
  //                   body: JSON.stringify({
  //                     name: `u-${keycloak.tokenParsed.sub}`,
  //                     versioning: false,
  //                     locking: false,
  //                   }),
  //                 })
  //                   .then((res) => {
  //                     // console.log(res);
  //                     // resource 作成成功
  //                     if (res.status === 201) {
  //                       console.log("makeBucket");
  //                     } else {
  //                       throw new Error("failed makeBucket");
  //                     }
  //                   })
  //                   .catch((error) => {
  //                     console.log("error", error);
  //                   })
  //                   .finally(() => {
  //                     // つくった
  //                     setBuckets([{ name: `u-${keycloak!.tokenParsed!.sub}` }]);
  //                   });
  //               } else {
  //                 setBuckets(dataBuckets.buckets);
  //               }
  //             } else {
  //               setBuckets(dataBuckets.buckets);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("error", error);
  //           });
  //       }
  //       window.removeEventListener("message", handler);
  //     }
  //   };
  //   window.addEventListener("message", handler);
  //   return () => {
  //     window.removeEventListener("message", handler);
  //   };
  // }, []);

  return (
    <Container fixed>
      <Title title="ダッシュボード" />
      <Grid container spacing={3} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                第2回 みんキャプ アワード へようこそ
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="body1" component="h5">
                運営委員会からの連絡事項欄
              </Typography>
              <ul>
                {/* <li>2022.01.17 点群(las)に対応。</li> */}
                <li>
                  2022.12.1 みんキャプ
                  アワードのエントリーが始まりました。ここには当システム (toMap)
                  関連の連絡事項を掲載します。それ以外の一般的な連絡は discord
                  で案内いたしますので みんキャプ アワード 期間中は定期的に
                  discord の みんキャプ
                  サーバーをご確認ください。操作に困られた時なども discord
                  にてご相談ください。
                </li>
                <li>
                  ギガ単位でアップロードしたいんだよ！という方など、できるかぎり対応いたしますので discord もしくは twitter でも メール でもなんでもいいのでご相談ください。プロも大歓迎です！
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card>
            <CardContent>
              <Typography variant="body1" component="h5">
                応募手順
              </Typography>
              <ol>
                <li>スマホや専用機でキャプチャして 3Dモデルを作る</li>
                <li>
                  Asset を新規作成する
                  <ol>
                    <li>3Dモデルをアップロード (glb, las に対応)</li>
                    <li>みんキャプデータ形式に [変換] するボタンをクリック</li>
                    <li>3Dモデルに 3Dデジタルマップ上の位置情報を設定する</li>
                  </ol>
                </li>
                <li>Project を新規作成し Asset を組み合わせる</li>
                <li>Project ごとに みんキャプ アワードに応募する</li>
              </ol>
            </CardContent>
          </Card>
          <Card style={{ marginTop: "1.5em" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                みんキャプ エントリー締め切り は 2023.1.15 です
              </Typography>
              <Typography variant="body2" component="p"></Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<LandscapeIcon />}
                component="a"
                href="https://mincap.tomap.app"
                target="_blank"
              >
                詳細
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card>
            <CardContent>
              <img src="/sharebutton.png" />
              <p>
                Project ができたら Project の画面から [みんキャプ VIEW]
                で開いて、その右上の共有ボタンから シェアURLを取得し ぜひ
                Twitter などで自慢してくださ〜い
              </p>
            </CardContent>
          </Card>
        </Grid>
        {/* 
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" display="inline">
                ストレージ
              </Typography>
              {buckets.length === 0 ? (
                <>
                  <Box>
                    <CircularProgress />
                  </Box>
                  <iframe
                    src={`./silent-check-sso-minio.html?api=${process.env.REACT_APP_MINIO_API_ENDPOINT}/login`}
                    style={{ display: "none" }}
                  ></iframe>
                </>
              ) : (
                <Box height={300}>
                  <FreeSpaceChart buckets={buckets} />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Dashboard;
