import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useKeycloak } from "@react-keycloak/web";
import { refreshView } from "ra-core";
import { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { FormHelperText } from "@material-ui/core";

// standby 最優先 にしても意味がない。超イレギュラーケースを無視すれば最新の1件だけよいし、その場合もタイムアウト処理などによって、クリーンになる。
const GET_PROCESS_TARGET = gql`
  query MyQuery($asset_id: uuid = "") {
    tomap_processes(
      where: {
        asset_id: { _eq: $asset_id }
        state: { _nin: ["done", "error"] }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      state
    }
  }
`;

const PROCESS_START = gql`
  mutation MyMutation($asset_id: uuid = "") {
    insert_tomap_processes_one(object: { asset_id: $asset_id }) {
      id
    }
  }
`;

const PROCESS_SUBSCRIPTION = gql`
  subscription MySubscription($id: uuid = "") {
    tomap_processes_by_pk(id: $id) {
      progress
      state
    }
  }
`;

// Split など
// https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended

// TODO: Initialize のタイミングをきちんと設定するには (最新のトークンを用いるべき)。createWebSocketLink 的なのがみつからない
// HttpLink https://www.apollographql.com/docs/react/networkin  g/authentication/
// WebsocketLink https://github.com/apollographql/subscriptions-transport-ws connectionParams に function をあたえればよさそう

// 再描写をおさえる方法いろいろ useSubscription をつかわず useEffect で自作
// ひょっとしたら lazy = true でもよかったのかもしれない => だめかもしれない
// https://github.com/apollographql/react-apollo/issues/3577

type ProcessProgressProps = {
  assetId?: string;
};

const ProcessProgress: React.VFC<ProcessProgressProps> = (props) => {
  const { assetId } = props;
  const form = useForm();
  const dispatch = useDispatch();

  const { keycloak } = useKeycloak();
  const [processId, setProcessId] = useState<string>("");
  const [data, setData] = useState<any>();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
      },
    };
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT!.replace("http", "ws"),

    options: {
      reconnect: true,
      // timeout: 30000,
      // lazy: true,
      connectionParams: () => {
        // 最新の token を持ち歩くように関数を返す (だが、ここまで必要かわからない)
        // すなおに object をセットするのでも大丈夫かとも思われるが、reconnect タイミングと処理がわからない
        // TODO: 長期接続中に一度切断し再接続のときに、token 有効期限がきれてしまっているテスト
        return {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        };
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink, // Invalid type
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  // TODO: 処理の進捗状況の表現自体を別窓化
  useEffect(() => {
    client
      .query({
        query: GET_PROCESS_TARGET,
        variables: { asset_id: assetId },
      })
      .then((d) => {
        console.log("query", d);
        const tomap_processes = d.data.tomap_processes;
        if (tomap_processes.length === 1) {
          setProcessId(tomap_processes[0].id);
        }
      })
      .catch((e) => {
        console.error("query", e);
      });
  }, [assetId]);

  // 再描写がひどくなるため useSubscription はつかわずに useEffect で DIY
  // const { data, loading, error } = useSubscription(
  //     PROCESS_SUBSCRIPTION,
  //     {
  //         variables: { id: "287229b3-25aa-44c5-946f-3a0001a93a7a" },
  //         onSubscriptionData: ({ client, subscriptionData }) => {
  //             console.log('onSubscriptionData', JSON.stringify(subscriptionData));
  //         },
  //         client: client
  //     },
  // );
  useEffect(() => {
    if (processId) {
      const observer = client.subscribe({
        query: PROCESS_SUBSCRIPTION,
        variables: {
          id: processId,
        },
      });

      const subscription = observer.subscribe(({ data }) => {
        console.log("SUBSCRIBE received", data);
        setData(data);
        if (data.tomap_processes_by_pk.state === "done") {
          // reload
          setTimeout(() => {
            dispatch(refreshView());
          }, 500);
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [processId]);

  // TODO: 引数で上位の Assets component で定義して持ってきたほうがきれい

  // if (loading) {
  //     return (<>loading now</>);
  // }
  // if (error) {
  //     console.log(error);
  //     return (<>error</>);
  // }
  return (
    <>
      {data && data.state !== "done" && (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={data.tomap_processes_by_pk.progress}
            />
            <FormHelperText>
              ※ 0%
              のままの状態は変換処理が停止しているのではなく処理の順番待ちをしている状態です。数時間経過しても全く動かない場合はお手数ですが
              Discord で運営委員会までご連絡ください
            </FormHelperText>
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              data.tomap_processes_by_pk.progress
            )}%`}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProcessProgress;
