import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import {
  FormControl,
  InputLabel,
  Input,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { Title } from "react-admin";
import SketchfabDialog, { SketchfabDialogProps } from "./SketchfabDialog";
import { useHistory } from 'react-router-dom';

// あとで 美しくしよう
// // https://v4.mui.com/components/image-list/
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';

// 32桁かしらどうかしら
// const SKETCHFAB_PRIVATE_ACCESS_TOKEN = "098c6f7f829c474b8a95c2007d42238a";

// 自分のモデル一覧
// https://docs.sketchfab.com/data-api/v3/index.html#!/models/get_v3_me_models

// Download API
// https://sketchfab.com/developers/download-api/downloading-models

const Sketchfab = () => {
  const { initialized, keycloak } = useKeycloak();
  const history = useHistory()

  const [models, setModels] = useState([]);
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [opendata, setOpendata] = useState(true);
  const [sketchfabPrivateAccessToken, setSketchfabPrivateAccessToken] =
    useState("");
  useEffect(() => {
    loadModels();
  }, [sketchfabPrivateAccessToken]);

  const [modalConfig, setModalConfig] = React.useState<
    SketchfabDialogProps | undefined
  >();

  const getGlbDownloadParams = async (model: any) => {
    var url = `https://sketchfab.beta.tomap.app/v3/models/${model.uid}/download`;
    var options = {
      method: "GET",
      headers: {
        Authorization: `token ${sketchfabPrivateAccessToken}`,
      },
    };
    const response = await fetch(url, options);
    const data = await response.json();
    // console.log({ data });

    // これと若干のパラメータをつけて resource server になげようかな
    // console.log(model, data);
    return data.glb;
  };

  // シンプルなチェックを
  const checkPrivateToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length == 32) {
      // Set が 間に合わないから useEffect にしうようかな
      setSketchfabPrivateAccessToken(e.target.value);
      // loadModels();
    }
  };

  const handleImportClick = async (model: any) => {
    const ret = await new Promise<string>((resolve) => {
      setModalConfig({
        onClose: resolve,
        title: "Sketchfab からインポート",
        message:
          "負荷が大きめの処理のため、重複してのインポート操作をしていないか念の為にご確認ください。",
        buttons: ["ok", "cancel"],
      });
    });
    setModalConfig(undefined);

    // 続けると setModalConfig が間に合わない
    if (ret === "ok") {
      console.log("OK時の処理を実行する");
      document.body.style.cursor = 'wait'

      // 名前とURLを取得してくる
      const params = await getGlbDownloadParams(model);
      console.log(params);
      // 直接 ion は難しいかな？？？ x-amz-security-token には非対応だ
      // "https://sketchfab-prod-media.s3.amazonaws.com/archives/36f540434b3d4f5d909b11b73b805932/glb/571db0a899e6486ba74d03ba890a1d47/36f540434b3d4f5d909b11b73b805932.glb?AWSAccessKeyId=ASIAZ4EAQ242PIUPG5LU&Signature=sJs%2FqmG%2FMopR%2F8k1oV1TKl0RLnw%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEHYaCWV1LXdlc3QtMSJHMEUCIF%2F0tjoYyiRVWqvfmhBjIyQ1ucvxbxNTaQTzIKq8tCzYAiEA6VJVPaiGnAayavdTz0aG%2F2EojERljlss%2BMxLEHTYfQsq1QQIn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw2Nzg4NzQzNzE4OTIiDITNxhefHYfEAbV5CSqpBJs%2BG8BrffbLA%2FNLry3T2LVZj909ie5vI%2FdNmj5g2Z31spI9HLNPXszOU1HnOJD7cYu6rlmAeKVPgvYXNvQVfoizQIgFhMhoV6Swq21cvFZN%2BFd9RMLM2D29953t2HA8xCSUlBspcs%2BHYTYBgBrNU%2Bj%2BxqbLtH9KtRhWLx0XB4xc1H0N7%2FqYiK%2BtRC4a1Uqi376q0vmrtSshkznPG0vJZsvtiWcp%2FG%2BjT1q6zp4bUpsb%2Bphx45f5ncUyE1w7jjQx4ZKd3Vq7sUGZM%2FnjWepGCyTg2YvW33VBVDsM8dJC%2BlApG54Ij0gmvywkYB5UD86Up0Nz8pdpwUOHifgdl1xqifDweZ5%2FF6VHACdGYPKzX5fFBXQZeXWWdlxICoWAPIOWK891wMSRH%2FwiMoV5%2BEfrhKItWrsgfXZcpni3H9pFwWJwufCtC8S61BGWm8NL9PY1WYKPFveg%2BpmS2pFqj0XuRMY%2Bi%2FLPzXPR8s7bvzHJVOsjH0NVMP7ydF1DuAaRyWwQzLx70k9QjuP8EwBA%2F1IStk6Xv5Q3%2FIk2YchYkF8Ov6VUg1RHdpg6hwQ2K5j6XCvGKszj04CHlzZzj8xDjT3ecTE9emLL8LtHX%2FvEIUL0iVaWa6Zda%2BrdSWlHx3dJBI1Jf23pw1YI5duri7BalNV66jksay5MeVyLeNTlktblCJnuTf5mlEUuM0qtakZOHWsN%2BFBByuax0yzTjb54DsLE9ZuijAulE8rY0Kcw4%2FnanAY6qQFrrJCUWD%2BxDv3nflDh9LYLhT4kfRS%2BY16u4nIYwgxTOQM25%2BQ%2Faa7%2F6dgurfmAezRxN9ijKjIfjUoAKA5MJ7Q9BbT6Xc3EaJMtRQ82do9e563pw68QEWyCCA9m4w7JdWH%2Fvill5WGbYiobAnXuicEHjSCbjgeIUuLpF0sh8j6qV3jx8UqO3VZmiEthGuOs7jed9HH7ezY83lN6qflrUQiFcR%2FpZllJEN5S&Expires=1670828008"
      // "https://sketchfab-prod-media.s3.amazonaws.com/archives/36f540434b3d4f5d909b11b73b805932/glb/571db0a899e6486ba74d03ba890a1d47/36f540434b3d4f5d909b11b73b805932.glb
      // ?AWSAccessKeyId=ASIAZ4EAQ242PIUPG5LU
      // &Signature=sJs%2FqmG%2FMopR%2F8k1oV1TKl0RLnw%3D
      // &x-amz-security-token=IQoJb3JpZ2luX2VjEHYaCWV1LXdlc3QtMSJHMEUCIF%2F0tjoYyiRVWqvfmhBjIyQ1ucvxbxNTaQTzIKq8tCzYAiEA6VJVPaiGnAayavdTz0aG%2F2EojERljlss%2BMxLEHTYfQsq1QQIn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw2Nzg4NzQzNzE4OTIiDITNxhefHYfEAbV5CSqpBJs%2BG8BrffbLA%2FNLry3T2LVZj909ie5vI%2FdNmj5g2Z31spI9HLNPXszOU1HnOJD7cYu6rlmAeKVPgvYXNvQVfoizQIgFhMhoV6Swq21cvFZN%2BFd9RMLM2D29953t2HA8xCSUlBspcs%2BHYTYBgBrNU%2Bj%2BxqbLtH9KtRhWLx0XB4xc1H0N7%2FqYiK%2BtRC4a1Uqi376q0vmrtSshkznPG0vJZsvtiWcp%2FG%2BjT1q6zp4bUpsb%2Bphx45f5ncUyE1w7jjQx4ZKd3Vq7sUGZM%2FnjWepGCyTg2YvW33VBVDsM8dJC%2BlApG54Ij0gmvywkYB5UD86Up0Nz8pdpwUOHifgdl1xqifDweZ5%2FF6VHACdGYPKzX5fFBXQZeXWWdlxICoWAPIOWK891wMSRH%2FwiMoV5%2BEfrhKItWrsgfXZcpni3H9pFwWJwufCtC8S61BGWm8NL9PY1WYKPFveg%2BpmS2pFqj0XuRMY%2Bi%2FLPzXPR8s7bvzHJVOsjH0NVMP7ydF1DuAaRyWwQzLx70k9QjuP8EwBA%2F1IStk6Xv5Q3%2FIk2YchYkF8Ov6VUg1RHdpg6hwQ2K5j6XCvGKszj04CHlzZzj8xDjT3ecTE9emLL8LtHX%2FvEIUL0iVaWa6Zda%2BrdSWlHx3dJBI1Jf23pw1YI5duri7BalNV66jksay5MeVyLeNTlktblCJnuTf5mlEUuM0qtakZOHWsN%2BFBByuax0yzTjb54DsLE9ZuijAulE8rY0Kcw4%2FnanAY6qQFrrJCUWD%2BxDv3nflDh9LYLhT4kfRS%2BY16u4nIYwgxTOQM25%2BQ%2Faa7%2F6dgurfmAezRxN9ijKjIfjUoAKA5MJ7Q9BbT6Xc3EaJMtRQ82do9e563pw68QEWyCCA9m4w7JdWH%2Fvill5WGbYiobAnXuicEHjSCbjgeIUuLpF0sh8j6qV3jx8UqO3VZmiEthGuOs7jed9HH7ezY83lN6qflrUQiFcR%2FpZllJEN5S
      // &Expires=1670828008"

      // zip アップロードして (zip ままで ion は受け入れてくれる)
      // s3 直接コピーはむずかしい
      const response = await fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/s3/from_sketchfab`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            url: params.url,
            size: params.size,
            name: model.name,
            opendata: opendata ? '"オープンデータ"' : "", // TODO: graphql と jsonb 調べるのめんどうで時間がないから、文字列渡し // TOFIX:
          }), // size は readable stream のために必要ぽかったけど content-length についてきたから不要
        }
      );

      const result = await response.json();
      console.log(result);
      document.body.style.cursor = 'default'

      const ret = await new Promise<string>((resolve) => {
        setModalConfig({
          onClose: resolve,
          title: "Sketchfab からインポート",
          message: "インポート処理を開始しました",
          buttons: ["ok"],
        });
      });

      if (ret == "ok") {
        history.push(`/assets/${result.asset_id}`)
      }

      setModalConfig(undefined);
    }
    if (ret === "cancel") {
      console.log("Cancel時の処理を実行する");
    }
  };

  // ページ一覧表示
  const loadModels = async (
    url: string = "https://sketchfab.beta.tomap.app/v3/me/models"
  ) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `token ${sketchfabPrivateAccessToken}`,
      },
    });
    const data = await response.json();
    console.log(data);
    setNext(data.next);
    setPrevious(data.previous);
    setModels(data.results);
  };

  // return <>{models && models.map((model:any, i) => (<><p key={i}>{model.name}</p><iframe src={model.embedUrl} /></>))}  </>;
  const thumbnailIx = 3;
  return (
    <>
      <Title title="Sketchfab の作品を みんキャプ にインポート" />

      <FormControl size="small" style={{ maxWidth: "40em" }}>
        <TextField
          id="outlined-basic"
          label="Sketchfab Private API Token"
          variant="outlined"
          type="password"
          size="small"
          helperText={
            <>
              <p>
                ※ この値は toMap および みんキャプ では保存しません (
                <a
                  href="https://sketchfab.com/settings/password"
                  target="_blank"
                >
                  Sketchfab の API Token ページへ
                </a>
                )
              </p>
              <p>
                ※ なお「3Dキャプチャ」した 3Dモデルはインポートに成功しがちですが、Blender などでつくった 3Dモデルはインポートに現状ほぼ失敗します。ご理解ください。
              </p>
            </>
          }
          // onBlur={loadModels}
          // onKeyDown={(e) => {
          //   if (e.code === "Enter") {
          //     // エンターキー押下時の処理
          //     loadModels();
          //   }
          // }}
          onChange={checkPrivateToken}
        />
        {/* <Button type="submit" variant="outlined" size="small" onClick={loadModels}>Sketchfab から読み込む</Button> */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={opendata}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOpendata(event.target.checked);
                }}
                name="checkedA"
              />
            }
            label={
              <>
                <a
                  href="https://github.com/mapconcierge/copyright-policy-jp/blob/main/OpenDataStandardTermsofUse/LICENSE.md"
                  target="_blank"
                >
                  オープンデータ標準利用規約（第1.0版）
                </a>
                に準拠してインポートする
              </>
            }
          />
        </FormGroup>
      </FormControl>

      {models && (
        <>
          <Grid container style={{ marginTop: "1em" }}>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-start">
                {previous && (
                  <Button
                    variant="outlined"
                    onClick={() => loadModels(previous)}
                  >
                    Prev
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-end">
                {next && (
                  <Button variant="outlined" onClick={() => loadModels(next)}>
                    Next
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {models.map((model: any, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <div className="model">
                  <p key={i}>{model.name}</p>
                  <div>
                    <img
                      height={model.thumbnails.images[thumbnailIx].height}
                      width={model.thumbnails.images[thumbnailIx].width}
                      src={model.thumbnails.images[thumbnailIx].url}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleImportClick(model);
                    }}
                  >
                    Import
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>

          <Grid container style={{ marginTop: "1em" }}>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-start">
                {previous && (
                  <Button
                    variant="outlined"
                    onClick={() => loadModels(previous)}
                  >
                    Prev
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-end">
                {next && (
                  <Button variant="outlined" onClick={() => loadModels(next)}>
                    Next
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {modalConfig && <SketchfabDialog {...modalConfig} />}
    </>
  );
};

export default Sketchfab;
