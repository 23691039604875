import { ReactKeycloakProvider } from "@react-keycloak/web";
import "cesium/Widgets/widgets.css";
import parser from "fast-xml-parser";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import keycloak from "./keycloak";
import reportWebVitals from "./reportWebVitals";
import { AppRouter } from "./routes";
// import * as serviceWorker from './serviceWorker'

import registerServiceWorker, {
  ServiceWorkerNoSupportError,
} from "./workers/storage.worker.ts";

registerServiceWorker({ scope: "/" })
  .then((reg) => {
    console.log("register Service Worker Success!", reg);
    console.log("reg.installing", reg.installing);
    console.log("reg.waiting", reg.waiting);
    console.log("reg.active", reg.active);

    if (reg.active) {
      //
    }

    reg.addEventListener("updatefound", () => {
      // A wild service worker has appeared in reg.installing!
      const newWorker = reg.installing!;
      console.log("updatefound", newWorker);

      // newWorker.state;
      // "installing" - the install event has fired, but not yet complete
      // "installed"  - install complete
      // "activating" - the activate event has fired, but not yet complete
      // "activated"  - fully active
      // "redundant"  - discarded. Either failed install, or it's been
      //                replaced by a newer version

      newWorker.addEventListener("statechange", () => {
        // console.log('state change', newWorker.state);
        // newWorker.state has changed
        if (newWorker.state === "activated") {
          // TODO: ここで assumeRoleCredentials をわたそう。
          console.log("state", newWorker.state, keycloak);
          // てぬき
          tokenLogger({ token: keycloak.token });
        }
      });
    });

    return () => {
      reg.unregister().then((flag: boolean) => {
        console.log("unregister success ?", flag);
      });
    };
  })
  .catch((err) => {
    if (err instanceof ServiceWorkerNoSupportError) {
      console.log("Service worker is not supported.");
    } else {
      console.log("Error!");
    }
  });

const eventLogger = (event: unknown, error: unknown) => {
  console.log("token eventLogger", event);
  if (error) {
    console.error("onKeycloakEvent", event, error);
    // setIsError(true);
  }
};

const tokenLogger = (tokens: any) => {
  // TODO: なぜか最初に2回呼ばれる。チューニングできる可能性あり。
  if (!tokens.token) return;

  console.log("token", tokens.token);
};

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{onload: "login-required"}}
      // initOptions={{
      //   enableLogging: true
      // }}
      // onEvent={eventLogger}
      // onTokens={tokenLogger}
      autoRefreshToken={true} // default true のはずが false になっているのかしら https://github.com/react-keycloak/react-keycloak/pull/164
    >
      <AppRouter />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
