import { Admin } from "@react-admin/ra-enterprise";
import {
  raTreeLanguageEnglish,
  reducer as tree,
} from "@react-admin/ra-tree-project-assets";
import { useKeycloak } from "@react-keycloak/web";
import { createBrowserHistory } from "history";
import { LegacyDataProvider, WithPermissions } from "ra-core";
import polyglotI18nProvider from "ra-i18n-polyglot";
import React, { useContext, useEffect, useState } from "react";
import { DataProvider, mergeTranslations, Resource } from "react-admin";
import { Route, Redirect } from "react-router";
import japaneseMessages from "./i18n/ja";
import Layout from "./Layout";
import Dashboard from "./pages/Dashboard";
import dataProviders from "./providers/dataProviders";
import keycloakAuthProvider from "./providers/keycloakAuthProvider";
import { AssetList, AssetCreate, AssetEdit } from "./resources/Assets";
import { AssetEditAdjust } from "./resources/Assets3";
import { ProjectCreate, ProjectEdit, ProjectList } from "./resources/Projects";
import {
  MincapEntryCreate,
  MincapEntryEdit,
  MincapEntryList,
  MincapIsExist,
} from "./resources/MincapEntries";
import { ModelList } from "./resources/Models";
import { darkTheme, lightTheme, theme } from "./theme";
import Sketchfab from "./components/Sketchfab";
import PresentAward from "./components/PresentAward";
import useInterval from "./utils/useInterval";

const history = createBrowserHistory();

// TODO: https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
// localstorage から memory に移動させよう

const i18nProvider = polyglotI18nProvider((locale) => {
  return mergeTranslations(japaneseMessages, raTreeLanguageEnglish);
}, "ja");

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  // const { getUsername, getAssumeRoleCredentials, storageInitialized } = useStorageAuth();
  const [dataProvider, setDataProvider] = useState<LegacyDataProvider | null>(
    null
  );

  const deadline = new Date(process.env.REACT_APP_DEADLINE!);
  const [checkDeadline, setCheckDeadline] = useState(new Date().getTime() >= deadline.getTime());

  useInterval(() => {
    const f = new Date().getTime() >= deadline.getTime()
    if (f && !checkDeadline) {
      setCheckDeadline(f);
      window.location.reload()       
    }
  }, 5000);

  useEffect(() => {
    if (initialized) {
      const buildDataProvider = async () => {
        const dataProvider = await dataProviders(keycloak);
        setDataProvider(() => dataProvider);
      };
      buildDataProvider();
    }
  }, [keycloak, initialized]); // keycloak 無視したほうがよさそうか検討

  if (!dataProvider) return <div>Loading ... (data)</div>;


  // TODO: Adjust リファクタリング
  return (
    <Admin
      layout={Layout}
      theme={theme}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      dashboard={Dashboard}
      disableTelemetry
      history={history}
      customRoutes={[
        <Route
          path="/mincap_entries/is_exist/:pid"
          render={(routeProps) => {
            const pid = routeProps.match.params.pid;
            return <MincapIsExist project_id={pid} />;
          }}
        />,
        <Route
          path="/assets/:id/adjust"
          render={(routeProps) => (
            <WithPermissions
              component={AssetEditAdjust}
              basePath="/assets"
              id={decodeURIComponent(
                // (routeProps.match as ResourceMatch).params.id
                routeProps.match.params.id
              )}
              {...routeProps}
              {...{
                resource: "assets",
                options: {},
                hasList: false,
                hasEdit: true,
                hasShow: false,
                hasCreate: false,
              }}
            />
          )}
        />,
        <Route
          path="/sketchfab"
          render={(routeProps) => {
            return <Sketchfab />;
          }}
        />,
        <Route
          path="/present_award/:award"
          render={(routeProps) => {
            const award = routeProps.match.params.award;
            return <PresentAward award={award} />;
          }}
        />,
        <Route
          path="/present_award"
          render={(routeProps) => {
            return <PresentAward />;
          }}
        />,
        // <Route path="/projects/:id/assets" component={ AssetsTree } />,
      ]}
      i18nProvider={i18nProvider}
      authProvider={keycloakAuthProvider}
      dataProvider={dataProvider}
      customReducers={{ tree }}
    >
      {/* @ts-ignore */}
      <Resource
        name="projects"
        options={{ label: "Projects" }}
        list={ProjectList}
        create={ProjectCreate}
        edit={ProjectEdit}
      />
      {/* @ts-ignore */}
      <Resource
        name="assets"
        options={{ label: "Assets" }}
        list={AssetList}
        create={AssetCreate}
        edit={AssetEdit}
      />
      {/* <Resource name="assets_files" options={{ label: "files" }} /> */}
      <Resource name="assets_taxons" />
      <Resource name="taxons" />
      <Resource name="projects_assets" />
      <Resource
        name="mincap_entries"
        list={MincapEntryList}
        create={checkDeadline ? undefined : MincapEntryCreate}
        edit={checkDeadline ? undefined : MincapEntryEdit}
      />
    </Admin>
  );
};

export default App;
