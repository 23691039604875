import { AppLocationContext } from "@react-admin/ra-navigation";
import { Layout } from 'react-admin';
import MyAppBar from './AppBar';
import MyMenu from './Menu';

const MyLayout = (props: any) => (
    <AppLocationContext>
        <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
    </AppLocationContext>);

export default MyLayout;