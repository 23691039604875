import React from 'react';

import { useLocation, useRouteMatch, Route } from 'react-router-dom';

// TODO: かた
// tabsPaths: string[]
const withHideOnTabs = (WrappedComponent: any) => {

    return (props: any) => {
        
        console.log('withHideOnTabs', props);
        const { children, tabsPaths, ...otherProps } = props;

        const location = useLocation();
        const match = useRouteMatch();

        // const regexp = new RegExp(`^${match.url}/?(.*)$$`);
        const regexp = new RegExp(`^${match.url}/?([^/]*)`);
        const tabPath = regexp.exec(location.pathname);

        if (tabPath && tabsPaths.indexOf(tabPath[1]) > -1) {
            return (<></>);
        }
        return (
            <WrappedComponent {...otherProps}>
                {children}
            </WrappedComponent>
        );
    };

};

export default withHideOnTabs;
