import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import keycloak from "../keycloak";

const Signin = (props: any) => {
  // console.log({keycloak})
  const { keycloak } = useKeycloak();

  const redirectUri = `mincap-pocket://signedin?token=${keycloak.token}`;
  // DeepLink には届く
  window.location.href = redirectUri;

  return (
    <>
      <a href={redirectUri}>Signed in, return to MinCap Pocket ...</a>
    </>
  );
};

const Signout = (props: any) => {
  const { keycloak } = useKeycloak();

  console.log(keycloak);
  const redirectUri = `mincap-pocket://signedout`;
  keycloak.logout({ redirectUri });

  return (
    <>
      Sign-out is in progress...
    </>
  );
};

const Pocket = (props: any) => {
  return (
    <>
      <Route path="/pocket/signin" component={Signin} />
      <Route path="/pocket/signout" component={Signout} />
    </>
  );
};

export default Pocket;
