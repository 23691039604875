import {
  MultiLevelMenu,
  MenuItem,
  MenuItemCategory,
} from "@react-admin/ra-navigation";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import ProjectIcon from '@material-ui/icons/Layers';
import ProjectIcon from "@material-ui/icons/Public";
import AssetIcon from "@material-ui/icons/HomeWork";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import { useKeycloak } from "@react-keycloak/web";

const ToMapMenu = () => {
  const { keycloak } = useKeycloak();

  // const { onMenuClick } = props;
  // const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  // const resources = useSelector(getResources);
  return (
    <MultiLevelMenu variant="categories">
      <MenuItemCategory
        name="dashboard"
        to="/"
        exact
        label="Dashboard"
        icon={<DashboardIcon />}
      />
      <MenuItemCategory
        name="assets"
        icon={<AssetIcon />}
        to="/assets"
        label="Assets"
      />
      <MenuItemCategory
        name="projects"
        icon={<ProjectIcon />}
        to="/projects"
        label="Projects"
      />
      <MenuItemCategory
        name="mincap_entries"
        icon={<img style={{ width: "24px" }} src="/mi.png" />}
        to="/mincap_entries"
        label="Mincap"
      />
      <MenuItemCategory
        name="sketchfab"
        icon={<img style={{ width: "24px" }} src="/sketchfab-logo.png" />}
        to="/sketchfab"
        label="Sketchfab"
      />
      {(keycloak.hasRealmRole("presenter") || keycloak.hasRealmRole("judge") ||
        keycloak.hasRealmRole("admin")) && (
        <MenuItemCategory
          name="present_award"
          icon={<EmojiEvents />}
          to="/present_award"
          label="賞を贈る"
        />
      )}
      {/* <DashboardMenuItem />
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))} */}
      {/* add your custom menus here */}
    </MultiLevelMenu>
  );
};

export default ToMapMenu;
