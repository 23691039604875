import { TranslationMessages } from 'react-admin';
import japaneseMessages from '@bicstone/ra-language-japanese';

const customJapaneseMessages: TranslationMessages = {
    ...japaneseMessages,
    resources: {
        dashboard: {
            title: 'ダッシュボード'
        },
        projects: {
            // name: 'プロジェクト |||| プロジェクト複数形' ,
            title: 'プロジェクト',  // Sidebar
            name: 'プロジェクト' // Header
        },
        assets: {
            title: 'アセット',
            name: 'アセット'
        }
    }
};

export default customJapaneseMessages;