import * as React from 'react'

import { useKeycloak } from '@react-keycloak/web'
import { useCallback } from 'react'
// import HomePage from '../pages/Home'
import App from '../App'
import Pocket from '../pages/Pocket'

// import { Route, Router, Redirect } from "react-router";
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';

export const AppRouter = (props: any) => {
  const { keycloak, initialized } = useKeycloak()

  // これあってもなかっても大差ない？ 2022.12 logout loop 検証
  // const login = useCallback(() => {
  //   keycloak?.login()
  // }, [keycloak])

  if (!initialized) {
    return <div>Loading ... (router)</div>
  }

  if (!keycloak?.authenticated) {
    keycloak.login();
    return <div>Login</div>
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/pocket' component={Pocket} />
        <Route path='/' component={App} />
      </Switch>
    </BrowserRouter>
  );
}