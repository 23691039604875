import { FormHelperText, useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { FormGroupProps } from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { SwitchProps } from "@material-ui/core/Switch";
import { AccordionSection } from "@react-admin/ra-form-layout";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { useKeycloak } from "@react-keycloak/web";
import _ from "lodash";
import { Record as RaRecord, useRecordContext } from "ra-core";
import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  BulkActionProps,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  Identifier,
  InputProps,
  List,
  ListProps,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useDataProvider,
  CheckboxGroupInput,
} from "react-admin";
import { useForm } from "react-final-form";
import { Link } from "react-router-dom";
import ProcessProgress from "../components/ProcessProgress";
import Tags from "../components/Tags";
// import "easymde/dist/easymde.min.css";
import UppyDashboard from "../components/UppyDashboard";
import withMeta from "../components/withMeta";

// export されていないから再宣言 todo: pull request
type BooleanInputProps = InputProps<SwitchProps> &
  Omit<FormGroupProps, "defaultValue" | "onChange" | "onBlur" | "onFocus">;

const useStyles = makeStyles({
  name: { minWidth: "512px" },
  booleanGroup: {
    "& label": {
      "margin-right": "auto",
    },
  },
  meta: {
    maxWidth: "40em",
  },
  toolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },

  //.MuiAccordion-root.Mui-expanded { margin: 16px 0; }
  accordionRoot: {
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      // background: 'yellow'
    },
  },
  fullWidth: {
    width: "100%",
  },
});

const AssetList = (props: ListProps | BulkActionProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <>
      <List
        {...props}
        title="resources.assets.title"
        bulkActionButtons={false}
        exporter={false}
        perPage={25}
        sort={{ field: "created_at", order: "DESC" }}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => record.created_at}
            linkType={(record) => (record.canEdit ? "edit" : "show")}
          />
        ) : (
          <Datagrid rowClick="edit">
            <TextField source="name" />
            <DateField source="created_at" showTime sortByOrder="DESC" />
            <EditButton />
          </Datagrid>
        )}
      </List>
      {/* <EditDialog {...props}>
              <SimpleForm>

              </SimpleForm>
          </EditDialog> */}
    </>
  );
};

const SimpleFormWithMetaCreate = withMeta(SimpleForm, (record: any) => {
  if (!record.metadata) {
    record.metadata = {};
  }
  if (!record.meta) {
    record.metaprivate = {};
  }
  const metadata = record.metadata;
  record.opendata = metadata.opendata;
});

const AssetCreate: FC<CreateProps> = (props) => {
  const { keycloak } = useKeycloak();
  const [choices, setChoices] = useState([
    { id: "3DCapture", name: "3Dキャプチャ (メッシュ化された glb" }, // 3d tiles になる、いったんこれですすめる
    // { id: "3DModelGltf", name: "3Dモデル" }, // to gltf はアニメーションも残る 位置情報の取り扱いが変わる というか 元々の感じですね
    { id: "PointCloud", name: "点群 (las, laz)" },
    // { id: "3DModelPlain", name: "3Dモデル (無変換)" }, // アニメーション問題があるとき 位置情報の取り扱いが変わる というか 元々の感じですね
    // { id: "3DModel3DTiles", name: "3Dモデル (3D-Tiles)" }, // BIM っぽいのとか？
  ]);
  const classes = useStyles();
  // useEffect(() => {
  //   setChoices(choices.concat({ id: "PontClouds", name: "点群 (las, laz)" }));
  //   setChoices(
  //     choices.concat({
  //       id: "3d models",
  //       name: "メッシュかされたのがうまくいかない場合",
  //     })
  //   ); // gltf
  //   setChoices(
  //     choices.concat({ id: "3d models2", name: "メッシュ化された広域" })
  //   );
  // }, []);

  const transform = async (data: RaRecord) => {
    // data.transform はどうしようか
    data.metadata.opendata = data.opendata;
    delete data.opendata;
    return data;
  };
  return (
    <Create {...props} transform={transform}>
      <SimpleFormWithMetaCreate
        initialValues={{
          type: "3DCapture",
          state: "created",
          opendata: ["オープンデータ"],
        }}
      >
        <TextInput
          autoFocus
          source="name"
          validate={[required()]}
          className={classes.name}
        />
        {/* <Typography variant="caption">※現在 gltf のみ対応</Typography> */}
        <RadioButtonGroupInput
          source="type"
          choices={
            choices
            // { id: 'pointcloud', name: 'pointcloud', disabled: true },
            // { id: '3dtiles', name: '3d-tiles', disabled: true },
          }
          helperText={false}
        />
        <FormHelperText className={classes.fullWidth}>
          ※ 点群(las, laz) はとりあえず変換できますが日本測地系などについて調整中です。
        </FormHelperText>

        <CheckboxGroupInput
          label="オープンデータ"
          source="opendata"
          choices={[
            {
              id: "オープンデータ",
              name: "オープンデータ標準利用規約（第1.0版）に準拠する",
            },
          ]}
          helperText={false}
        />
        <FormHelperText className={classes.fullWidth}>
          ※&nbsp;
          <a
            href="https://github.com/mapconcierge/copyright-policy-jp/blob/main/OpenDataStandardTermsofUse/LICENSE.md"
            target="_blank"
          >
            オープンデータ標準利用規約（第1.0版）はこちら
          </a>
        </FormHelperText>
      </SimpleFormWithMetaCreate>
    </Create>
  );
};

// tag の初期化など
const FormWrapper = (props: any) => {
  const { children, record } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const dataProvider = useDataProvider();
  const form = useForm();

  // TODO: useEffect ではなく生読み込みでもよいかもだけど初期化回数多くなりすぎる？
  // いったん毎回読み込みで。
  useEffect(() => {
    (async () => {
      const tags = await dataProvider.getList("assets_taxons", {
        pagination: { page: 1, perPage: 999 },
        sort: { field: "created_at", order: "ASC" },
        filter: { asset_id: record.id },
      });

      if (tags) {
        // data.tags = tags.data;
        record.tags = tags.data.map((tag) => tag.taxon_id);
      }

      // record だとタイミングがおかしい。でも form.change だと、dirty になる。
      // initialize だと大丈夫っぽい。
      // form.initialize(record);
      form.change("tags", record.tags);
      setLoading(false);
    })();
  });
  // },[]);

  if (loading) {
    return <div>loading...</div>;
  }

  return <>{children}</>;
};

type AssetSectionProps = {
  state: string;
  children: ReactNode;
};

const AssetSection = (props: AssetSectionProps) => {
  const record = useRecordContext();
  const { children, state } = props;

  // websocket ここで実装する？

  if (state === record.state) {
    return <>{children}</>;
  } else {
    return null;
  }
};

const SelectEPSG = (props: any) => {
  const record = useRecordContext();
  const classes = useStyles(props);

  return record.type === "las" ? (
    <>
      <SelectInput
        label="基準とする座標系"
        source="fromEPSG"
        helperText={false}
        choices={[
          { id: "EPSG:4978", name: "EPSG:4978 WGS84 (無変換)" }, // 4326
          {
            id: "3d Scanner App - LAS Geo-Referenced",
            name: "3d Scanner App - LAS Geo-Referenced",
          },
          { id: "EPSG:6669", name: "EPSG:6669 1系 長崎県、鹿児島県の一部" },
          {
            id: "EPSG:6670",
            name: "EPSG:6670 2系 福岡県、佐賀県、熊本県、大分県、宮崎県、鹿児島県の一部",
          },
          { id: "EPSG:6671", name: "EPSG:6671 3系 山口県、島根県、広島県" },
          {
            id: "EPSG:6672",
            name: "EPSG:6672 4系 香川県、愛媛県、徳島県、高知県",
          },
          { id: "EPSG:6673", name: "EPSG:6673 5系 兵庫県、鳥取県、岡山県" },
          {
            id: "EPSG:6674",
            name: "EPSG:6674 6系 京都府、大阪府、福井県、滋賀県、三重県、奈良県、和歌山県",
          },
          {
            id: "EPSG:6675",
            name: "EPSG:6675 7系 石川県、富山県、岐阜県、愛知県",
          },
          {
            id: "EPSG:6676",
            name: "EPSG:6676 8系 新潟県、長野県、山梨県、静岡県",
          },
          {
            id: "EPSG:6677",
            name: "EPSG:6677 9系 東京都の一部、福島県、栃木県、茨城県、埼玉県、千葉県、群馬県、神祭川県",
          },
          {
            id: "EPSG:6678",
            name: "EPSG:6678 10系 青森県、秋田県、山形県、岩手県、宮城県",
          },
          { id: "EPSG:6679", name: "EPSG:6679 11系 北海道の一部" },
          { id: "EPSG:6680", name: "EPSG:6680 12系 北海道の一部" },
          { id: "EPSG:6681", name: "EPSG:6681 13系 北海道の一部" },
          { id: "EPSG:6682", name: "EPSG:6682 14系 東京都の一部" },
          { id: "EPSG:6683", name: "EPSG:6683 15系 沖縄県の一部" },
          { id: "EPSG:6684", name: "EPSG:6684 16系 沖縄県の一部" },
          { id: "EPSG:6685", name: "EPSG:6685 17系 沖縄県の一部" },
          { id: "EPSG:6686", name: "EPSG:6686 18系 東京都の一部" },
          { id: "EPSG:6687", name: "EPSG:6687 19系 東京都の一部" },
        ]}
      />
      <FormHelperText className={classes.fullWidth}>
        <a
          href="https://www.gsi.go.jp/sokuchikijun/jpc.html"
          target="_blank"
          rel="noreferrer"
        >
          参考) 日本の座標系 https://www.gsi.go.jp/sokuchikijun/jpc.html
        </a>
      </FormHelperText>
    </>
  ) : null;
};

const SimpleFormWithMeta = withMeta(SimpleForm, (record: any) => {
  // record.mainFile="fullfilenameb"
  const metadata = record.metadata;

  record.opendata = metadata.opendata;
  record.description = metadata.description;

  const metaprivate = record.metaprivate;
  record.fromEPSG = metaprivate.fromEPSG ? metaprivate.fromEPSG : "EPSG:4978";
});

const ButtonConvert = (props: any) => {
  const form = useForm();
  const onConvertStandBy = () => {
    form.change("state", "processStandby");
    // form.change('fromEPSG', 'hoge');
    form.submit();
  };
  return <Button {...props} onClick={onConvertStandBy} />;
};

const OpenDataEditor = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (record.opendata && record.opendata.length > 0) {
    return <div>オープンデータ標準利用規約（第1.0版）に準拠する</div>;
  } else {
    return (
      <CheckboxGroupInput
        label="オープンデータ"
        source="opendata"
        choices={[
          {
            id: "オープンデータ",
            name: "オープンデータ標準利用規約（第1.0版）に準拠する",
          },
        ]}
        helperText={false}
      />
    );
  }
};

const AssetEdit: FC<EditProps> = (props) => {
  const asset_id = props.id;
  const dataProvider = useDataProvider();

  const classes = useStyles(props);

  const redirect = (basePath: string, id: Identifier, data: any) => {
    return `/assets/${id}`;
  };

  // TODO: ケース分けをもう一段階明確に。もしくは JSONB専用 Mutation 拡張を
  const transform = async (data: RaRecord) => {
    // data.transform はどうしようか
    data.metadata.opendata = data.opendata;
    delete data.opendata;

    data.metadata.description = data.description;
    delete data.description;

    data.metaprivate.fromEPSG = data.fromEPSG;
    delete data.fromEPSG;

    // まず削除が必要
    // ダサいけどしかたないぼちぼち消す
    // TODO: かっこよく消そう
    const old_tags = await dataProvider.getList("assets_taxons", {
      pagination: { page: 1, perPage: 999 },
      sort: { field: "created_at", order: "ASC" },
      filter: { asset_id: data.id },
    });
    const old_tags_ids = old_tags.data.map((d) => d.id);

    // old_tags と比較
    if (!_.isEqual(old_tags_ids, data.tags)) {
      await dataProvider.deleteMany("assets_taxons", {
        ids: old_tags.data.map((t) => t.id),
      });

      data.tags.forEach(async (tag: any) => {
        await dataProvider.create("assets_taxons", {
          data: { asset_id: data.id, taxon_id: tag },
        });
      });
    }
    delete data.tags;
    // けしてみる？

    // console.log('transform', JSON.stringify(data));
    return data;
  };

  // const UserEditToolbar = (props: any) => {
  //   const record = props.record;
  //   return (<Toolbar {...props} classes={useStyles()}>
  //     <SaveButton {...props} />
  //     <DeleteButton redirect={`/projects/${record.project_id}/assets`} mutationMode="optimistic" />
  //   </Toolbar>
  //   );
  // }
  const record = useRecordContext();
  console.log(record);
  return (
    <Edit
      {...props}
      transform={transform}
      mutationMode="pessimistic"
      title="アセット を編集"
    >
      <SimpleFormWithMeta redirect={redirect}>
        <FormWrapper>
          <TextInput
            source="name"
            validate={required()}
            className={classes.name}
          />

          <OpenDataEditor />
          <FormHelperText className={classes.fullWidth}>
            ※&nbsp;
            <a
              href="https://github.com/mapconcierge/copyright-policy-jp/blob/main/OpenDataStandardTermsofUse/LICENSE.md"
              target="_blank"
            >
              オープンデータ標準利用規約（第1.0版）はこちら
            </a>
          </FormHelperText>
          <br />
          <AssetSection state="processed">
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={`/assets/${asset_id}/adjust`}
              >
                位置情報を設定する
              </Button>
            </Box>
          </AssetSection>
          <AssetSection state="jibaku">
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={`/assets/${asset_id}/adjust`}
              >
                位置情報を設定する
              </Button>
              <p></p>
            </Box>
            <Tags source="tags" />

            <MarkdownInput
              source="description"
              options={{
                toolbarItems: [
                  "heading",
                  "bold",
                  "italic",
                  "divider",
                  "hr",
                  "quote",
                  "divider",
                  "ul",
                  "ol",
                  "task",
                  "indent",
                  "outdent",
                  "divider",
                  "table",
                  "link",
                  "divider",
                  "code",
                  "codeblock",
                ],
              }}
            />
          </AssetSection>
          <AssetSection state="created">
            <AccordionSection
              className={classes.accordionRoot}
              label="ファイルのアップロード"
              fullWidth
              defaultExpanded
            >
              <UppyDashboard />
            </AccordionSection>
          </AssetSection>
          <AssetSection state="uploaded">
            <AccordionSection
              className={classes.accordionRoot}
              label="変換処理"
              fullWidth
              defaultExpanded
            >
              <SelectEPSG />
              <ButtonConvert variant="contained" color="secondary">
                変換処理
              </ButtonConvert>
            </AccordionSection>
          </AssetSection>
          <AssetSection state="processStandby">
            <AccordionSection
              className={classes.accordionRoot}
              label="変換処理進捗"
              fullWidth
              defaultExpanded
            >
              <ProcessProgress assetId={asset_id} />
            </AccordionSection>
          </AssetSection>
          {/* <SelectArrayInput optionText="name" /> */}
          {/* <AutocompleteArrayInput
            source="meta"
            create={<CreateTag />}
            // choices={[]}
            // onCreate=p{() => {
            //     const newTagName = prompt('Enter a new tag');
            //     if (newTagName) {
            //         const newTag = { id: newTagName.toLowerCase(), name: newTagName };
            //         // categories.push(newTag);
            //         return newTag;
            //     }
            // }}
            shouldRenderSuggestions={(value: string) => value.length >= 2}
          /> */}
        </FormWrapper>
      </SimpleFormWithMeta>
    </Edit>
  );
};

export { AssetList, AssetCreate, AssetEdit };
