import {
  Box,
  FormHelperText,
  Typography,
  makeStyles,
  Card,
} from "@material-ui/core";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { useAppLocationMatcher } from "@react-admin/ra-navigation";
import React, { useEffect, useState } from "react";
import {
  Title,
  ReferenceField,
  AutocompleteInput,
  BooleanField,
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { Redirect, useLocation } from "react-router-dom";
import withMeta from "../components/withMeta";
import { Record as RaRecord } from "ra-core";
import { useKeycloak } from "@react-keycloak/web";
const useStyles = makeStyles({
  niceWidth: {
    minWidth: "512px",
  },
  fullWidth: {
    width: "100%",
    margin: "1em 0",
  },
});

const OpenDataEditor = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (record.opendata && record.opendata.length > 0) {
    return <div>オープンデータ標準利用規約（第1.0版）に準拠する</div>;
  } else {
    return (
      <CheckboxGroupInput
        label="オープンデータ"
        source="opendata"
        choices={[
          {
            id: "オープンデータ",
            name: "オープンデータ標準利用規約（第1.0版）に準拠する",
          },
        ]}
        helperText={false}
      />
    );
  }
};

const prefChoices = [
  { id: "北海道", name: "北海道" },
  { id: "青森県", name: "青森県" },
  { id: "岩手県", name: "岩手県" },
  { id: "宮城県", name: "宮城県" },
  { id: "秋田県", name: "秋田県" },
  { id: "山形県", name: "山形県" },
  { id: "福島県", name: "福島県" },
  { id: "茨城県", name: "茨城県" },
  { id: "栃木県", name: "栃木県" },
  { id: "群馬県", name: "群馬県" },
  { id: "埼玉県", name: "埼玉県" },
  { id: "千葉県", name: "千葉県" },
  { id: "東京都", name: "東京都" },
  { id: "神奈川県", name: "神奈川県" },
  { id: "新潟県", name: "新潟県" },
  { id: "富山県", name: "富山県" },
  { id: "石川県", name: "石川県" },
  { id: "福井県", name: "福井県" },
  { id: "山梨県", name: "山梨県" },
  { id: "長野県", name: "長野県" },
  { id: "岐阜県", name: "岐阜県" },
  { id: "静岡県", name: "静岡県" },
  { id: "愛知県", name: "愛知県" },
  { id: "三重県", name: "三重県" },
  { id: "滋賀県", name: "滋賀県" },
  { id: "京都府", name: "京都府" },
  { id: "大阪府", name: "大阪府" },
  { id: "兵庫県", name: "兵庫県" },
  { id: "奈良県", name: "奈良県" },
  { id: "和歌山県", name: "和歌山県" },
  { id: "鳥取県", name: "鳥取県" },
  { id: "島根県", name: "島根県" },
  { id: "岡山県", name: "岡山県" },
  { id: "広島県", name: "広島県" },
  { id: "山口県", name: "山口県" },
  { id: "徳島県", name: "徳島県" },
  { id: "香川県", name: "香川県" },
  { id: "愛媛県", name: "愛媛県" },
  { id: "高知県", name: "高知県" },
  { id: "福岡県", name: "福岡県" },
  { id: "佐賀県", name: "佐賀県" },
  { id: "長崎県", name: "長崎県" },
  { id: "熊本県", name: "熊本県" },
  { id: "大分県", name: "大分県" },
  { id: "宮崎県", name: "宮崎県" },
  { id: "鹿児島県", name: "鹿児島県" },
  { id: "沖縄県", name: "沖縄県" },
  { id: "海外", name: "海外" },
];

const partnerChoices = [
  {
    id: "東京都デジタルツイン賞",
    name: "東京都デジタルツイン賞",
  },
  {
    id: "VIRTUAL SHIZUOKA 賞",
    name: "VIRTUAL SHIZUOKA 賞",
  },
  {
    id: "「へぇ〜」と言ってしまう神戸のうわさ賞",
    name: "「へぇ〜」と言ってしまう神戸のうわさ賞",
  }, // { id: "アクセンチュア賞", name: "アクセンチュア賞（仮）" },
  { id: "若葉賞", name: "若葉賞" },
  { id: "Scaniverse Award", name: "Scaniverse Award" },
  { id: "ナイス！地縛賞", name: "ナイス！地縛賞" },
  { id: "路地賞", name: "路地賞" },
  { id: "四国賞", name: "四国賞" },
  { id: "なんか凄いで賞", name: "なんか凄いで賞" },
  {
    id: "どこでもいつまでも一緒！○○パネル賞",
    name: "どこでもいつまでも一緒！○○パネル賞",
  },
  { id: "ひげだるま賞", name: "ひげだるま賞" },
  { id: "鉄道遺産賞", name: "鉄道遺産賞" },
  {
    id: "みんなでつくろう！磐梯町メタバース賞",
    name: "みんなでつくろう！磐梯町メタバース賞",
  },
  { id: "吾妻真田忍キャプ賞", name: "吾妻真田忍キャプ賞" },
  { id: "デジタルツイン渋谷賞", name: "デジタルツイン渋谷賞" },
  { id: "瓦頭斎賞", name: "瓦頭斎賞" },
  { id: "インフラストラクチュア賞", name: "インフラストラクチュア賞" },
  { id: "石棒クラブ賞", name: "石棒クラブ賞" },
  {
    id: "お正月賞",
    name: "お正月賞",
  },
];

// テーマ賞を審査員賞につかおう
const themeChoices = [
  { id: "PLATEAU賞", name: "PLATEAU賞（内山審査員）" },
  { id: "文化財3D賞", name: "文化財3D賞（野口審査員）" },
  { id: "一億総伊能化賞", name: "一億総伊能化賞（古橋審査員）" },
  { id: "楽しそうな遊具で賞", name: "楽しそうな遊具で賞 (前畑審査員)" },
];

const deviceChoices = [
  { id: "iPhone/iPad (LiDAR搭載）", name: "iPhone/iPad (LiDAR搭載）" },
  { id: "iPhone/iPad（LiDAR未搭載）", name: "iPhone/iPad（LiDAR未搭載）" },
  { id: "Android", name: "Android" },
  // { id: "Leica BLK360", name: "Leica BLK360" },
  // { id: "Leica RTC360", name: "Leica RTC360" },
  // { id: "Matterport Pro2 3D", name: "Matterport Pro2 3D" },
];

const appChoices = [
  { id: "Scaniverse", name: "Scaniverse" },
  { id: "WIDAR", name: "WIDAR" },
  { id: "Metascan", name: "Metascan" },
  { id: "Polycam", name: "Polycam" },
  // { id: "3d Scanner App", name: "3d Scanner App" },
  // { id: "Partikle", name: "Partikle" },
  { id: "Trnio", name: "Trnio" },
  { id: "TrnioPlus", name: "TrnioPlus" },
  { id: "EveryPoint", name: "EveryPoint" },
  { id: "SiteScape", name: "SiteScape" },
  { id: "RealityCapture", name: "RealityCapture" },
  // { id: "Cyclone REGISTER 360", name: "Cyclone REGISTER 360" },
];

const Aside = () => {
  return (
    <Card style={{ width: "20%", marginLeft: "2em", padding: "1em" }}>
      <Typography variant="h6">みんキャプの詳細は</Typography>
      <Typography variant="body2">
        <a href="https://2022.minc.app">https://2022.minc.app</a>
      </Typography>
      エントリー締め切りは 2023/01/15 (日)
    </Card>
  );
};

const MincapEntryList = (props: any) => {
  const match = useAppLocationMatcher();
  return (
    <List
      {...props}
      title="みんキャプ エントリー 一覧"
      aside={<Aside />}
      bulkActionButtons={false}
      perPage={25}
      exporter={false}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField
          label="Project Name"
          source="project_id"
          reference="projects"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
};

const SimpleFormWithMeta = withMeta(SimpleForm, (record: any) => {
  if (!record.metadata) {
    record.metadata = {};
  }
  const metadata = record.metadata;
  record.armband = metadata.armband;
  record.nickname = metadata.nickname;
  record.discord = metadata.discord;
  record.pref = metadata.pref;
  record.industry = metadata.industry;
  record.share_url = metadata.share_url;
  record.partners = metadata.partners;
  record.themes = metadata.themes;
  record.body = metadata.body;
  record.devices = metadata.devices;
  record.device_other = metadata.device_other;
  record.apps = metadata.apps;
  record.app_other = metadata.app_other;
  record.agree_terms = metadata.agree_terms;
  record.opendata = metadata.opendata;
});

const transform = async (data: RaRecord) => {
  if (!data.metadata) {
    data.metadata = {};
  }
  data.metadata.armband = data.armband;
  data.metadata.nickname = data.nickname;
  data.metadata.discord = data.discord;
  data.metadata.pref = data.pref;
  data.metadata.industry = data.industry;
  data.metadata.share_url = data.share_url;
  data.metadata.partners = data.partners;
  data.metadata.themes = data.themes;
  data.metadata.body = data.body;
  data.metadata.devices = data.devices;
  data.metadata.device_other = data.device_other;
  data.metadata.apps = data.apps;
  data.metadata.app_other = data.app_other;
  data.metadata.agree_terms = data.agree_terms;

  data.metadata.opendata = data.opendata;

  // console.log(data);
  // TODO: 丁寧に消す方が気持ちよい
  // delete data.nickname;
  // delete data.discord;
  return data;
};

type ErrorObject = {
  themes?: string;
  partners?: string;
  devices?: string;
  apps?: string;
};
const validateMincapEntry = (values: any) => {
  const errors: ErrorObject = {};
  if (values.themes?.length === 0 && values.partners?.length === 0) {
    errors.themes = "審査員賞かパートナー賞を選んでください";
    errors.partners = "審査員賞かパートナー賞を選んでください";
  }

  if (values.devices?.length === 0 && !values.device_other) {
    errors.devices = "デバイスを選択するか入力してください";
  }
  if (values.apps?.length === 0 && !values.app_other) {
    errors.apps = "アプリを選択するか入力してください";
  }
  return errors;
};

// プロジェクトにつき応募は1回だけ
// TODO: 自身のプロジェクトのみ (Graphql 側の制約にしてもよいか)
const validateProject = (value: any, allValues: any) => {};

const MincapEntryForm = (props: any) => {
  const { initialized, keycloak } = useKeycloak();

  const classes = useStyles();
  const search = useLocation().search;

  const query2 = new URLSearchParams(search);
  const project_id = query2.get("project_id");

  const [defaultValues, setDefault] = useState<any>(null)
  useEffect( ()=>{
    (async ()=>{
      const response = await fetch(
        process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT!,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `query MyQuery($userId: uuid = "") {
              tomap_mincap_entries(limit: 1, order_by: {updated_at: desc}, where: {user_id: {_eq: $userId}}) {
                metadata
              }
            },
          `,
            variables: { userId: keycloak.tokenParsed?.sub },
          }),
        }
      );
      const result = await response.json();
      if (result.data.tomap_mincap_entries.length == 1) {
        const metadata = result.data.tomap_mincap_entries[0].metadata
        setDefault({
          armband: metadata.armband,
          pref: metadata.pref,
          industry: metadata.industry,
          discord: metadata.discord,
          nickname: metadata.nickname
        })        
      } else {
        setDefault({})
      }
    })()
  },[])

  if (defaultValues === null) return <></>

  return (
    <SimpleFormWithMeta
      {...props}
      initialValues={{ ...defaultValues, project_id, opendata: ["オープンデータ"] }}
      validate={validateMincapEntry}
    >
      <Typography className={classes.fullWidth}>
        エントリー先着１００名様に第一回みんキャプ参加特別賞として、みんキャプオリジナル腕章をプレゼント！
      </Typography>

      <CheckboxGroupInput
        label="不要の方はチェックしてください（住所連絡が面倒だったり、前回GETしたから２つ目は要らないよという方）"
        source="armband"
        choices={[
          {
            id: "腕章不要",
            name: "先着プレゼントの腕章は不要です",
          },
        ]}
        helperText={false}
      />

      <Typography variant="h6">ご自身に関すること</Typography>

      <TextInput
        source="nickname"
        label="ニックネームもしくは本名"
        validate={[required()]}
        helperText="※ チャットなどの呼びかけに用います"
        className={classes.niceWidth}
      />
      <TextInput
        source="discord"
        label="Discordアカウント"
        validate={[required()]}
        helperText="※ 運営委員会からの連絡に用います"
        className={classes.niceWidth}
      />
      <SelectInput
        source="pref"
        label="居住地"
        choices={prefChoices}
        validate={[required()]}
      />
      <TextInput
        source="industry"
        label="業界"
        validate={[required()]}
        helperText="例）建設、公務員、学生など"
      />

      <Typography variant="h6">作品情報</Typography>
      <ReferenceInput
        source="project_id"
        reference="projects"
        validate={[required()]}
        enableGetChoices={(filters) => filters.q && filters.q.length >= 2}
        filterToQuery={(q) => ({ name: q })}
        className={classes.niceWidth}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="share_url"
        label="シェアURL"
        helperText="こだわりのカメラ画角などある場合は、みんキャプ View のシェアURLを入力"
        className={classes.niceWidth}
      />
      <MarkdownInput
        label="アピールポイント"
        helperText="作品に対する想い、コンセプト、エピソード、見てほしいポイントなど"
        source="body"
        options={{
          toolbarItems: [
            "heading",
            "bold",
            "italic",
            "divider",
            "hr",
            "quote",
            "divider",
            "ul",
            "ol",
            "task",
            "indent",
            "outdent",
            "divider",
            "table",
            "link",
            "divider",
            "code",
            "codeblock",
          ],
        }}
      />

      <CheckboxGroupInput
        label="エントリーする審査員賞"
        source="themes"
        choices={themeChoices}
        optionText={(record:any)=>{return <a href={`https://2022.minc.app/prize#${record.id}`} target='_blank'>{`${record.name}`}</a>}}
      />

      <CheckboxGroupInput
        label="エントリーする連携イベントおよびパートナー賞"
        source="partners"
        choices={partnerChoices}
        helperText={false}
        optionText={(record:any)=>{return <a href={`https://2022.minc.app/prize#${record.id}`} target='_blank'>{`${record.name}`}</a>}}
      />
      <FormHelperText className={classes.fullWidth}>
        ※
        東京都デジタルツイン賞に応募の際は、同賞「応募上の注意」を遵守ください。({" "}
        <a href="https://info.tokyo-digitaltwin.metro.tokyo.lg.jp/">
          https://info.tokyo-digitaltwin.metro.tokyo.lg.jp/
        </a>{" "}
        ) <br />
        ※ Scaniverse Award は Scaniverse アプリで
        3Dキャプチャされたものが対象となります。
        <br />※ その他各賞の詳細については{" "}
        <a href="https://2022.minc.app" target="_blank">
          https://2022.minc.app
        </a>
        をご確認ください。
      </FormHelperText>

      <CheckboxGroupInput
        label="利用したデバイス"
        source="devices"
        choices={deviceChoices}
        helperText={false}
      />
      <TextInput
        source="device_other"
        label="上記以外の場合こちらにご入力ください"
        className={classes.niceWidth}
      />
      <CheckboxGroupInput
        label="利用したアプリ"
        source="apps"
        choices={appChoices}
        helperText={false}
      />
      <TextInput
        source="app_other"
        label="上記以外の場合こちらご入力ください"
        className={classes.niceWidth}
      />

      <OpenDataEditor />

      <CheckboxGroupInput
        label="規約に同意する"
        source="agree_terms"
        choices={[{ id: "同意", name: "規約に同意する" }]}
        validate={[required()]}
        helperText={false}
      />
      <FormHelperText>
        <a href="https://2022.minc.app/tos" target="_blank">
          みんキャプ応募に関する規約こちら
        </a>
      </FormHelperText>
    </SimpleFormWithMeta>
  );
};

const MincapEntryCreate = (props: any) => {
  return (
    <Create {...props} title="みんキャプ エントリー" transform={transform}>
      <MincapEntryForm />
    </Create>
  );
};

const MincapEntryEdit = (props: any) => (
  <Edit
    {...props}
    title="みんキャプ エントリー内容を更新する"
    transform={transform}
  >
    <MincapEntryForm />
  </Edit>
);

// Async
// http://localhost:4000/mincap_entries/is_exist/5d6c90b8-58e9-4e4b-98e6-912fb3a6c7ac
const MincapIsExist = (props: any) => {
  const dataProvider = useDataProvider();
  const [redirectTo, setRedirectTo] = useState("");
  const { project_id, ...rest } = props;

  // console.log('dataProvider', dataProvider);
  // const data = await dataProvider(GET_ONE, 'mincap_entries', { id: pid });
  // const data = dataProvider.getOne('mincap_entries', { id: pid });
  // console.log('project is exists', data);
  useEffect(() => {
    // TODO: user_id も確認したい
    (async () => {
      // getOne は id にしか対応していない
      const res = await dataProvider.getList("mincap_entries", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "project_id", order: "ASC" },
        filter: { project_id },
      });
      if (res.total === 1) {
        setRedirectTo(`/mincap_entries/${res.data[0].id}}`);
      } else {
        setRedirectTo(`/mincap_entries/create/?project_id=${project_id}`);
      }
    })();
  }, []);

  const mid = project_id;

  if (!redirectTo) return null;

  return <Redirect to={redirectTo} />;
};

export { MincapEntryList, MincapEntryCreate, MincapEntryEdit, MincapIsExist };
