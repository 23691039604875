import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useKeycloak } from "@react-keycloak/web";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Japanese from "@uppy/locales/lib/ja_JP";
import { Dashboard } from "@uppy/react";
import { refreshView, useRecordContext } from "ra-core";
import React, { useEffect } from "react";
import { TextField } from "react-admin";
import { useForm } from "react-final-form";
import { useDispatch } from "react-redux";
// import AwsS3MultipartHelper from "../utils/awsS3MultipartHelper";
// import AssetFiles from "./AssetFiles";

const allowdFileTypes: { [key: string]: string[] } = {
  gltf: [".glb"],
  las: [".las", ".laz"],
  "3DCapture": [".glb"],
  "PointCloud": [".las", ".laz"],
};

const mainFilenames: { [key: string]: string } = {
  gltf: "model.glb",
  las: "model.las", // pointCloud.las はくどいかな main.las も
};

// const AssetPicker = ({ currentAvatar }) => {
const UppyDashboard = (props: any) => {
  const record = useRecordContext();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const tokenParsed = keycloak.tokenParsed as any;

  const user_id = tokenParsed.sub;
  const asset_id = record.id;

  const form = useForm();

  const uploaded = (result: any) => {
    console.log("uploaded");
    // form.change('metaprivate', { "originalFilename": result.name });

    // las のときは converted
    // switch (record.type) {
    //   case "gltf":
    //     form.change("state", "uploaded"); // processed にしてしまうかどうか
    //     break;
    //   case "las":
    //     form.change("state", "uploaded");
    //     break;
    // }

    form.change("metaprivate", { files: result.successful });
    form.change("state", "uploaded");

    form.submit();
    // reload
    setTimeout(() => {
      dispatch(refreshView());
    }, 500);
  };

  const uppy = new Uppy({
    meta: { type: "avatar" },
    // restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false, // false
    allowMultipleUploads: true, // true,
    restrictions: {
      maxNumberOfFiles:
        record.type === "gltf" || record.type === "3DCapture" ? 1 : 0, // ファイルタイプによって制限をかけるかどうか
      allowedFileTypes: allowdFileTypes[record.type],
      maxFileSize:
        1024 *
        1024 *
        (keycloak?.realmAccess?.roles.includes("tester") ? 1024 : 300), // 300MiB
    },
    locale: Japanese,
    onBeforeUpload: (files) => {
      // const awsS3MultipartHelper = new AwsS3MultipartHelper({
      //   bucket: `u-${user_id}`, // user_id
      //   acl: "private", // TODO: 要確認 これはユーザー設定ができるように。Asset のパスで管理したい。
      //   token: keycloak.token!,
      // });

      // asset-id をわたしてみる // TODO: model.glb にする？
      const uploadKeyBase: string = `a-${asset_id}/uploads/`; // ${mainFilenames[record.type]}`;

      // 一回外しておく。あとでもどそう。
      // const awsS3MultipartOptions = {
      //   limit: 5,
      //   createMultipartUpload: (file: any) => awsS3MultipartHelper.createMultipartUpload(file, uploadKeyBase),
      //   listParts: (file: any, options: any) => awsS3MultipartHelper.listParts(file, options),
      //   prepareUploadParts: (file: any, partData: any) => awsS3MultipartHelper.prepareUploadParts(file, partData),
      //   abortMultipartUpload: (file: any, options: any) => awsS3MultipartHelper.abortMultipartUpload(file, options),
      //   completeMultipartUpload: (file: any, options: any) => awsS3MultipartHelper.completeMultipartUpload(file, options),
      // }
      // // // 初期化いっかいでいい
      // uppy.use(AwsS3Multipart, awsS3MultipartOptions);

      console.log("uppy on before-upload", files);
      for (let key in files) {
        files[key].meta.name = encodeURI(files[key].meta.name);
      }
      return files;
    },
  }).use(AwsS3, {
    getUploadParameters(file) {
      console.log("getUploadParameters", file);
      // Send a request to our PHP signing endpoint.
      return fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/s3/uploader`, {
        method: "post",
        // Send and receive JSON.
        headers: {
          authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          filename: `u-${user_id}/a-${asset_id}/${file.name}`, // TODO: 日本語とか記号とかいろいろ動作確認したい
          contentType: file.type,
        }),
      })
        .then((response) => {
          // Parse the JSON response.
          return response.json();
        })
        .then((data) => {
          // Return an object in the correct shape.
          return {
            method: data.method,
            url: data.url,
            fields: data.fields, // For presigned PUT uploads, this should be left empty.
            // Provide content type header required by S3
            headers: {
              "Content-Type": file.type!,
            },
          };
        });
    },
  });

  // TODO: Dialog
  uppy.on("error", (error) => {
    console.log("uppy on error", error);
  });

  // 複数ファイルアップロード時は、ファイル数の回数呼び出される
  // uppy.on("upload-success", (result) => {
  //   // console.log("uppy on upload-success", result);
  //   uploaded(result);
  // });

  // 複数ファイルにも対応するためにこちらで処理するのがよいかも
  uppy.on("complete", (result) => {
    if (result.failed.length > 0) {
      console.log("failed files:", result.failed);
    }
    if (result.successful.length > 0) {
      console.log("successful files:", result.successful);
      uploaded(result);
    }
  });

  // useEffect(() => {
  //   // TODO: credential 取得タイミングなど確認すること
  //   // 参考
  //   // https://gist.github.com/vojtad/28eb4c43806513d7fd2196f97e11a9e8
  //   const awsS3MultipartHelper = new AwsS3MultipartHelper({
  //     bucket: `u-${user_id}`, // user_id
  //     acl: "private", // TODO: 要確認 これはユーザー設定ができるように。Asset のパスで管理したい。
  //     token: keycloak.token!
  //   });

  //   // asset-id をわたしてみる // TODO: model.glb にする？
  //   const uploadKeyBase: string = `a-${asset_id}/uploads/`; // ${mainFilenames[record.type]}`;
  //   console.log('hogehoge awsS3MultipartHelper', keycloak.token, uploadKeyBase);

  //   const awsS3MultipartOptions = {
  //     limit: 5,
  //     createMultipartUpload: (file: any) => awsS3MultipartHelper.createMultipartUpload(file, uploadKeyBase),
  //     listParts: (file: any, options: any) => awsS3MultipartHelper.listParts(file, options),
  //     prepareUploadParts: (file: any, partData: any) => awsS3MultipartHelper.prepareUploadParts(file, partData),
  //     abortMultipartUpload: (file: any, options: any) => awsS3MultipartHelper.abortMultipartUpload(file, options),
  //     completeMultipartUpload: (file: any, options: any) => awsS3MultipartHelper.completeMultipartUpload(file, options),
  //   }
  //   // // 初期化いっかいでいい
  //   uppy.use(AwsS3Multipart, awsS3MultipartOptions);
  // }, [asset_id]);

  return (
    <Box>
      <TextField label="type" source="type" /> (
      {allowdFileTypes[record.type].join(",")}{" "}
      ファイル形式。ファイル名は英数のみを推奨)
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Dashboard
            width="100%"
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <AssetFiles />
        </Grid> */}
      </Grid>
      <Box>
        <Typography variant="caption">
          ※ 1ファイルの上限は、300MiB です。
        </Typography>
      </Box>
      {(record.type === "las" || record.type === "PointCloud" ) && (
        <>
          <Box>
            <Typography variant="caption">
              ※ 複数の las
              ファイルをアップロードいただけます。変換時に併合処理を行います。
            </Typography>
          </Box>
          {/* <Box>
            <Typography variant="caption">※ 変換処理が終了したらストレージ容量削減のためにアップロードしたファイルは削除いただいても構いません。</Typography>
          </Box> */}
        </>
      )}
    </Box>
  );
};

export default UppyDashboard;
