import React, { forwardRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// TODO: createPortal 対応

// TODO: ESC キー

export interface SketchfabDialogProps {
  onClose: (value: string) => void;
  title?: string;
  message?: string;
  buttons?: string[];
}

const SketchfabDialog = (props: SketchfabDialogProps) => {
  const { onClose, title, message, buttons } = props;

  // buttons は component 受けをしてもいいけどとりいそぎ

  return (
    <Dialog
      open
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {(buttons == undefined || buttons.indexOf("ok") != -1) && (
          <Button
            onClick={() => onClose("ok")}
            color="primary"
            autoFocus={buttons == undefined || buttons.length == 1}
          >
            OK
          </Button>
        )}
        {buttons != undefined && buttons.indexOf("cancel") != -1 && (
          <Button onClick={() => onClose("cancel")} color="primary" autoFocus>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SketchfabDialog;
