// TODO: Warning: React does not recognize the basePath prop on a DOM element. https://github.com/marmelab/react-admin/issues/4209

import React, { FC } from 'react';
import {
    Edit,
    EditProps,
    SimpleForm,
    TextField,
    ReferenceField,
    Identifier,
    useGetIdentity,
    Toolbar,
    SaveButton,
    Button
} from 'react-admin';
import JibakuIcon from '@material-ui/icons/Public';
import { makeStyles } from '@material-ui/core/styles';
import withMeta from '../components/withMeta';
import { Record as RaRecord, useRecordContext, useRedirect } from 'ra-core';
import { useKeycloak } from '@react-keycloak/web';

// TODO: 差分でのアップデートが必要？
// https://github.com/worklifebeyond/wlb-ra-data-hasura

import Adjust from './Adjust';
// TODO: リレーションもってくること https://www.npmjs.com/package/ra-data-hasura-graphql#example-extending-a-query-to-include-related-entities

const AssetEditAdjust: FC<EditProps> = props => {
    const { loading: identityLoading } = useGetIdentity();
    const { initialized, keycloak } = useKeycloak();

    if (identityLoading || !initialized) return <div>Loading...</div>;
    const useStyles = makeStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

    const SimpleFormWithMeta = withMeta(SimpleForm, (record: any) => {
        // record.mainFile="fullfilenameb"
        const metadata = record.metadata;
        record.offset = {
            ...metadata.offset
        };
        record.transform = metadata.transform || [];
    });

    const UserEditToolbar = (props: any) => {
        const record = props.record;
        const redirect = useRedirect();
        return (
            <Toolbar {...props} classes={useStyles()}>
                <SaveButton label="地縛る" icon={<JibakuIcon />} color="secondary" />
                <Button label="戻る" onClick={() => { redirect(`/assets/${record.id}`) }} />
            </Toolbar>)
    };

    const redirect = (basePath: string, id: Identifier, data: any) => {
        return `/assets/${id}`;
    };

    // save for meta は transform で。この transform は react-admin のそれで、アフィン変換のそれではない
    const transform = (data: RaRecord) => {
        data.metadata.offset = data.offset;
        data.metadata.transform = data.transform;
        data.state = 'jibaku';
        // meta に統合
        delete data.offset;
        delete data.transform;
        // けしてみる？

        const params = { transform: JSON.stringify(data.metadata.transform) };
        const query = new URLSearchParams(params)

        // TODO: 移動させること、いったんここで tileset.wrap.json つくるか
        fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/asset/${data.user_id}/${data.id}/tileset.wrap.json?${query}`,
            {
                method: 'POST', headers: new Headers({
                    'Authorization': `bearer ${keycloak.token}`
                })
            }).then((r) => (r.json())).then(j => { console.log(j) });

        return data;
    };

    return (
        <Edit {...props} transform={transform} mutationMode="pessimistic" title="アセット の位置情報設定">
            <SimpleFormWithMeta redirect={redirect} toolbar={<UserEditToolbar />}>
                <TextField source="name" label="Asset.name" />
                <Adjust {...props} />
            </SimpleFormWithMeta>
        </Edit>
    );
};


export { AssetEditAdjust };
