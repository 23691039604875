// 手順 https://github.com/ma   rmelab/react-admin/blob/master/docs/Authentication.md#building-your-own-auth-provider
// 例えば https://github.com/benwinding/react-admin-firebase/blob/master/src/providers/AuthProvider.ts
// https://github.com/MrHertal/react-admin-amplify/blob/master/src/providers/AuthProvider.ts
// js だけど丁寧だが古い？ https://github.com/marmelab/ra-example-oauth/blob/master/app/src/authProvider.js

// TODO: refreshToken


// React Component 
// import { useKeycloak } from '@react-keycloak/web'

// import { promises } from 'node:dns';
// import { promises } from 'node:dns';
import { AuthProvider } from 'react-admin';
import keycloak from '../keycloak';
// import decodeJwt from 'jwt-decode';

// keycloak.init({
//     onLoad: 'login-required'    // これいい仕事しそう？
// }).then(authenticated => {
//     console.log("init then", authenticated);
// }

// );

// keycloak.init(options).then(function (authenticated) {
//     alert(authenticated ? 'authenticated' : 'not authenticated');
// }).catch(function () {
//     alert('failed to initialize');
// });;

// in src/authProvider.js
// const KeycloakAuthProvider: AuthProvider = {
//     login: ({ username, password }) => {
//         console.log("hogehoge"); 
//         return Promise.reject();
//         // return keycloak.login();
//     },

//     checkError: (error) => {
//         console.log("error", error);
//         return Promise.reject();
//         //  return Promise.resolve();
//          },
//     checkAuth: () => {
//         console.log("checkAuth");
//         return Promise.reject() },
//     logout: () => Promise.reject(), //  keycloak.logout(),    // redirectUri
//     // getIdentity: () => Promise.reject(), //Promise.resolve({id:"hoge", fullName:"foo", avatar:"bar.png"}), // keycloak.loadUserProfile(),
//     // authorization
//     getPermissions: (params) => { return Promise.reject() },
// }

// export default KeycloakAuthProvider;


const keycloakAuthProvider: AuthProvider = {
    // ログインは原則ここを通らない
    login: ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_KEYCLOAK_FRONTEND_URL + '/realms/tomap/protocol/openid-connect/token', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                // const decodedToken = decodeJwt(token);
                // localStorage.setItem('token', token);
                // localStorage.setItem('permissions', decodedToken.permissions);
            });
    },
    checkError: (error) => {
        console.log({error})
        return Promise.reject()
    },

    checkAuth: () => {
        console.trace('keycloak checkAuth', keycloak.authenticated)
        return keycloak.authenticated ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        console.log("keycloak logout");
        // return keycloak.logout({ redirectUri: `${window.location.protocol}//${window.location.host}`});
        return keycloak.logout({ redirectUri: 'https://minc.app'});
        // window.location.href = "http://auth.beta.tomap.app/auth/realms/tomap/protocol/openid-connect/logout?redirect_uri=" + `${window.location.protocol}//${window.location.host}`;
        // const request = new Request('https://auth.beta.tomap.app/auth/realms/tomap/protocol/openid-connect/logout', {
        //     method: 'GET',
        // });
        // return fetch(request).then(response => {
        //     console.log(request);
        //     return response.json();
        // }
        // );
        
        // return Promise.resolve();   
        // http://サーバー名:ポート/auth/realms/レルム名/protocol/openid-connect/logout
        // return keycloak.logout();
    },
    getIdentity: () => keycloak.loadUserProfile().then(KeycloakProfile => {
        console.log('keycloak getIdentity')
        return Promise.resolve({ id: KeycloakProfile.id!, fullName: KeycloakProfile.username, avatar: KeycloakProfile.username })
      }),

    // export interface UserIdentity {
    //     id: Identifier;
    //     fullName?: string;
    //     avatar?: string;
    //     [key: string]: any;
    // }

    // interface KeycloakProfile {
    // 	id?: string;
    // 	username?: string;
    // 	email?: string;
    // 	firstName?: string;
    // 	lastName?: string;
    // 	enabled?: boolean;
    // 	emailVerified?: boolean;
    // 	totp?: boolean;
    // 	createdTimestamp?: number;
    // }

    getPermissions: () => {
        // ここかな
        // roles: ["offline_access", "admin", "uma_authorization"]
        return Promise.resolve(keycloak.realmAccess?.roles.indexOf('admin') !== -1 ? 'admin' : 'member');  
    }
};

export default keycloakAuthProvider;