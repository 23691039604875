import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { ThemeOptions } from '@material-ui/core';
// import { createTheme } from '@material-ui/core/styles';

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=1976D2&secondary.color=2E7D32
const theme = merge({}, defaultTheme, {
    palette: {

    },
});

const lightTheme: ThemeOptions= {
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change. // TODO: v5 から mode に
        primary: {
            main:  blue[700],
            // light: '#60ad5e',
            // dark: '#005005',
            // contrastText: '#fff'
        },
        secondary: {
            main: green[800],
            // light: '#63a4ff',
            // dark: '#004ba0',
            // contrastText: '#fff'
        },
    },
};


const darkTheme: ThemeOptions= {
    palette: {
        type: 'dark', // Don't forget to specify the palette type
        primary: {
            main: blue[300],
        },
        secondary: {
            main: green[900],
        },
    },
};

export {theme, lightTheme, darkTheme};
