// TODO: npm module 化。その際は、process.env.REACT_APP_RESOURCE_SERVER は初期化パラメータなりに

type ElevationType = {
    elevation: number;
    geoidHeight: number;
    ellipsoidalHeight: number;
};

export type ElevationParamType = {
    /**
     * 経度
     *
     * @type {number}
     */
    lon: number;
    /**
     * 緯度
     *
     * @type {number}
     */
    lat: number;
};

/**
 * 緯度経度から、標高とジオイド高、そして楕円体高を取得します。（国土地理院提供データ利用）
 *
 * @param {ElevationParamType} { lon, lat }
 * @return {*}  {Promise<ElevationType>}
 */
async function getElevation({ lon, lat }: ElevationParamType): Promise<ElevationType> {
    try {
        // まず標高 https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?lon=138.24198260210028&lat=35.91569761810479&outtype=JSON
        const elevationParams = new URLSearchParams({ lon: lon.toString(), lat: lat.toString(), outtype: "JSON" });
        const elevationRes = await fetch(`https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?${elevationParams.toString()}`);
        // {"elevation":978,"hsrc":"5m\uff08\u30ec\u30fc\u30b6\uff09"}
        const elevationJson = await elevationRes.json();
        const elevation = elevationJson.elevation;

        // TODO: 自作してしまうか検討。perl で動いているし、いつか整理整頓で APIサーバーを止められる可能性もありそう
        // 国土地理院 測量計算サイト https://vldb.gsi.go.jp/sokuchi/surveycalc/api_help.html
        // こっちは CORS にひっかかる。reverse_proxy を通すか検討（同一IPアドレスからのリクエストは、10秒間で10回 制限あり）
        // ジオイド高  https://vldb.gsi.go.jp/sokuchi/surveycalc/geoid/calcgh/cgi/geoidcalc.pl?outputType=json&latitude=35.91569761810479&longitude=138.24198260210028
        const geoidParams = new URLSearchParams({ longitude: lon.toString(), latitude: lat.toString(), outputType: "json" });
        // const geoidRes = await fetch(`https://vldb.gsi.go.jp/sokuchi/surveycalc/geoid/calcgh/cgi/geoidcalc.pl?${geoidParams.toString()}`);
        const geoidRes = await fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/geoidcalc?${geoidParams.toString()}`);
        // { "OutputData": { "latitude": "35.915697618", "longitude": "138.241982602", "geoidHeight": "43.6292" } }
        const geoidJson = await geoidRes.json();

        const geoidHeight = parseFloat(geoidJson.OutputData.geoidHeight);

        return { elevation, geoidHeight, ellipsoidalHeight: elevation + geoidHeight };

    } catch (err) {
        // TODO: 再処理なり
        return { elevation: 0, geoidHeight: 0, ellipsoidalHeight: 0 };
    }
};

export { getElevation };