import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import Link from '@material-ui/core/Link';

const MyUserMenu = (props: any) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        component={Link}
        to="#"
        href={process.env.REACT_APP_KEYCLOAK_FRONTEND_URL + "/realms/tomap/account/"}
        target="_blank"
        primaryText="My Profile"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
};

export default MyUserMenu;
