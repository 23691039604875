import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import MapIcon from "@material-ui/icons/Map";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { useKeycloak } from "@react-keycloak/web";
import { Record as RaRecord, usePermissions, useRecordContext } from "ra-core";
import React, { FC, useState } from "react";
import {
  BooleanInput,
  BulkActionProps,
  Button,
  Create,
  CreateButton,
  CreateProps,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  Link,
  List,
  ListActionsProps,
  ListProps,
  ReferenceManyField,
  required,
  SimpleForm,
  SimpleList,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useTranslate,
} from "react-admin";
import AssetTotal from "../components/AssetTotal";
import { AssetsTree } from "../components/ProjectAssets";
import withHideOnTabs from "../components/withHideOnTabs";
import withMeta from "../components/withMeta";
import useInterval from "../utils/useInterval";

const MyActions: FC<ListActionsProps> = (props) => {
  const { loaded } = usePermissions();
  return loaded ? (
    <TopToolbar>
      <CreateButton variant="outlined" label="add Project" />
    </TopToolbar>
  ) : null;
};

const TabbedFormWithMeta = withMeta(TabbedForm, (record: any) => {
  // record.mainFile="fullfilenameb"
  // console.log('metadata', record);
  const metadata = record.metadata;
  // record.preload = metadata.preload;
  // record.isShown = metadata.isShown;
  record.description = metadata.description;
});

const transform = async (data: RaRecord, keycloak: any) => {
  // data.transform はどうしようか
  // console.log('transform', data);
  if (!data.metadata) {
    data.metadata = {};
  }
  data.metadata.description = data.description;
  // data.metadata.preload = data.preload;
  // data.metadata.isShown = data.isShown;
  // delete data.preload;
  // delete data.isShown;
  delete data.description;

  // TODO: 変化したときのみ呼び出すように
  if (data.is_published) {
    fetch(
      `${process.env.REACT_APP_RESOURCE_SERVER}/project/${data.id}/publish`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `bearer ${keycloak.token}`,
        }),
      }
    )
      .then((r) => r.json())
      .then((j) => {
        console.log(j);
      });
  }

  return data;
};

const ProjectList = (props: ListProps | BulkActionProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <>
      <List
        {...props}
        title="resources.projects.title"
        bulkActionButtons={false}
        exporter={false}
        actions={<MyActions />}
        perPage={25}
        sort={{ field: "created_at", order: "DESC" }}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => record.created_at}
            linkType={(record) => (record.canEdit ? "edit" : "show")}
          />
        ) : (
          <Datagrid rowClick="edit">
            {/* <TextField source="id" /> */}
            <TextField source="name" />
            <ReferenceManyField
              textAlign="right"
              sortable={false}
              label="Assets"
              reference="projects_assets"
              target="project_id"
            >
              <AssetTotal />
            </ReferenceManyField>
            <DateField source="created_at" showTime sortByOrder="DESC" />
            <EditButton />
          </Datagrid>
        )}
      </List>
      {/* <EditDialog {...props}>
                <SimpleForm>

                </SimpleForm>
            </EditDialog> */}
    </>
  );
};

/////////////////////////////////////////////////////

const useStyles = makeStyles({
  name: {
    minWidth: "512px",
  },
  meta: {
    maxWidth: "40em",
  },
});

const requiredValidate = [required()];

const ProjectCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);
  const { initialized, keycloak } = useKeycloak();

  // redirect 修正だよね アセットは オブジェクト追加へ
  return (
    <Create
      {...props}
      transform={async (data) => {
        return await transform(data, keycloak);
      }}
    >
      <SimpleForm redirect="edit">
        <SectionTitle label="resources.projects.title" />
        <TextInput
          autoFocus
          source="name"
          className={classes.name}
          validate={requiredValidate}
        />
        <MarkdownInput source="description" />
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

type LinkActionsProps = {
  id: string | undefined;
};
const LinkActions: FC<LinkActionsProps> = (props) => {
  const { permissions } = usePermissions();

  // const host = (tokenParsed.group && tokenParsed.group[0]) ? tokenParsed.group[0].slice(1) : "demo";
  const host = "mincap";

  const deadline = new Date(process.env.REACT_APP_DEADLINE!);
  const [checkDeadline, setCheckDeadline] = useState(new Date().getTime() >= deadline.getTime());

  useInterval(() => {
    const f = new Date().getTime() >= deadline.getTime()
    if (f && !checkDeadline) {
      setCheckDeadline(f);
      // window.location.reload()       
    }
  }, 5000);

  // const group = keycloak.tokenParsed?.group[0];
  return (
    <TopToolbar>
      {/* <Link
        to={`//${host}${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/#p-${props.id}`}
        target="_blank"
      >
        <Button
          label="toMap View"
          variant="contained"
          color="primary"
          startIcon={<MapIcon />}
        />
      </Link> */}
      <Link
        // to={`//${host}${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/#p-${props.id}&plateau`}
        to={`//${host}${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/#p-${props.id}`}
        target="_blank"
      >
        <Button
          label="みんキャプ View で開く"
          variant="contained"
          color="secondary"
          startIcon={<MapIcon />}
        />
      </Link>
      &nbsp;
      { checkDeadline ||
      <Link to={`/mincap_entries/is_exist/${props.id}`}>
        <Button label="賞に応募する" variant="outlined" color="secondary" />
      </Link>
      }
    </TopToolbar>
  );
};

// TODO: 削除確認ダイアログのタイトル差し替え 作業途中
// fork して利用し、プルリクするほうが正解かもしれない。ほかにも ReferenceManyField など、結果が０件のときの表示ガイダンス追加などプルリクしたい。
const CustomToolbar = (props: any) => {
  const { children, ...rest } = props;
  return <Toolbar {...rest} />;
};

// これと syncWithLocation = {false} の相性もよくない
const ToolbarWithHiddenTabs = withHideOnTabs(CustomToolbar);

// タブとサブフォームの関係を Hack すること
// 左のメニューは useAppLocationMatcher で制御がつよい
// tab は TabbedFormView (esm) の exact で制御がつよい
// いったん syncWithLocation={false} で回避 (左のメニューは回避できていない)

/*
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock'],
*/
// TODO: image を blob hook

const LinkMincap = (props: any) => {
  const record = useRecordContext();
  return record.is_published ? (
    <>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Link
          to={`//mincap${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/#p-${record.id}`}
          target="_blank"
        >
          <Button
            label="みんキャプで開く"
            variant="contained"
            color="secondary"
            startIcon={<MapIcon />}
          />
        </Link>
      </Box>

      <ReferenceManyField
        reference="mincap_entries"
        target="project_id"
        addLabel={false}
      >
        <Datagrid
          empty={
            <Box sx={{ pt: 2 }}>
              <Link to={`/mincap_entries/is_exist/${props.id}`}>
                <Button
                  label="エントリーフォームに進む"
                  variant="outlined"
                  color="secondary"
                />
              </Link>
            </Box>
          }
        >
          {/* <Datagrid> */}
          <TextField source="id" label="エントリーID" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          {/* <EditButton /> */}
        </Datagrid>
      </ReferenceManyField>
    </>
  ) : null;
};

const ProjectEdit: FC<EditProps> = ({ permissions, ...props }) => {
  const classes = useStyles(props);
  const { initialized, keycloak } = useKeycloak();

  return (
    <Edit
      transform={async (data) => {
        return await transform(data, keycloak);
      }}
      mutationMode="optimistic"
      actions={<LinkActions id={props.id} />}
      title="プロジェクト を編集"
      {...props}
    >
      <TabbedFormWithMeta
        tab={<div></div>}
        syncWithLocation={false}
        toolbar={<ToolbarWithHiddenTabs tabsPaths={["/"]} />}
        redirect="edit"
      >
        <FormTab label="main">
          <TextInput
            autoFocus
            source="name"
            className={classes.name}
            validate={requiredValidate}
          />
          <MarkdownInput
            source="description"
            options={{
              toolbarItems: [
                "heading",
                "bold",
                "italic",
                "divider",
                "hr",
                "quote",
                "divider",
                "ul",
                "ol",
                "task",
                "indent",
                "outdent",
                "divider",
                "table",
                "link",
                "divider",
                "code",
                "codeblock",
              ],
            }}
          />
        </FormTab>

        <FormTab label="assets Tree" path="assets">
          <AssetsTree {...props} />
        </FormTab>

        {/* <FormTab label="みんキャプ" path="mincap">
          <BooleanInput
            fullWidth={true}
            source="is_published"
            label="みんキャプに公開する"
            helperText="※ アセットを追加変更されたときは一度非公開にして保存し、再度公開手続きをお願いいたします。"
          />

          <LinkMincap {...props} />
        </FormTab> */}

        {/* <FormTab label="assets" path="assets2">
                    <ReferenceManyField reference="assets" target="project_id" addLabel={false} sort={{ field: 'created_at', order: 'DESC' }}>
                        <Datagrid rowClick="edit">
                            <TextField source="name" />
                            <TextField source="type" />
                            <DateField source="created_at" showTime />
                            <DeleteButton redirect={`/projects/${props.id}/assets`} mutationMode="optimistic" />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab> */}
      </TabbedFormWithMeta>
    </Edit>
  );
};

export { ProjectList, ProjectCreate, ProjectEdit };
