import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Title, Link } from "react-admin";
const useStyles = makeStyles({
  table: {
    maxWidth: 300,
    fontSize: 12,
  },
});

const PresentAward = (props: any) => {
  const award = props.award;

  const { initialized, keycloak } = useKeycloak();
  const [data, setData] = useState<any>({});
  const classes = useStyles();

  // やや脆弱性があるが許容範囲とする

  useEffect(() => {
    (async () => {
      const response = await fetch(
        process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT!,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json",
            "x-hasura-role": keycloak.hasRealmRole("admin")
              ? "admin" 
              : (keycloak.hasRealmRole("judge") ? "judge" : "presenter"),
          },
          body: JSON.stringify({
            query: `query MyQuery {
            main : tomap_view_for_presenter(order_by: {updated_at: desc, project_id:asc}
               , distinct_on: [updated_at, project_id] 
                ${
                  (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("judge")) && award !== undefined
                    ? `, where: {award: {_eq: "${award}"}}`
                    : ""
                }
              ) {
              updated_at
              themes
              share_url
              industry
              project_name
              project_id
              partners
              opendata
              id
              devices
              device_other
              created_at
              body
              apps
              app_other
              screening
            }
            tomap_view_for_presenter_count(order_by: {count: desc}) {
              award
              count
            },
            tomap_mincap_entries_aggregate {
              aggregate {
                count
              }
            }          
          }
          `,
          }),
        }
      );
      const result = await response.json();

      // トータルをここで計算しておく... いみがない。これだと述べだった
      // const total_count = result.data.tomap_view_for_presenter_count.reduce((r:number, row:any)=> r + row.count ,0)
      // console.log(total_count)

      setData(result.data);
    })();
  }, [award]);

  // return <>{models && models.map((model:any, i) => (<><p key={i}>{model.name}</p><iframe src={model.embedUrl} /></>))}  </>;
  const thumbnailIx = 3;
  return (
    <Grid container spacing={2}>
      <Title title="審査用のページです" />
      <Grid item xs={12} sm={2}>
        {data && data.tomap_view_for_presenter_count && (
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>エントリーされている賞ごとの数</TableCell>
                  <TableCell align="right">数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="total">
                  <TableCell component="th" scope="row">
                    {keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("judge") ? (
                      <Link to={`/present_award`}>総応募数</Link>
                    ) : (
                      "総応募数"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {data.tomap_mincap_entries_aggregate.aggregate.count}
                  </TableCell>
                </TableRow>
                {data.tomap_view_for_presenter_count.map(
                  (row: any, i: number) => (
                    <TableRow key={row.award}>
                      <TableCell component="th" scope="row">
                        {keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("judge") ? (
                          <Link to={`/present_award/${row.award}`}>
                            {row.award}
                          </Link>
                        ) : (
                          row.award
                        )}
                      </TableCell>
                      <TableCell align="right">{row.count}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12} sm={10}>
        {data && data.main && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="150">プロジェクト</TableCell>
                  <TableCell width="100">share_url</TableCell>
                  <TableCell width="300">説明</TableCell>
                  <TableCell>賞</TableCell>
                  <TableCell>業種</TableCell>
                  <TableCell>利用デバイス</TableCell>
                  <TableCell>利用アプリ</TableCell>
                  <TableCell>オープンデータ</TableCell>
                  <TableCell>応募日時</TableCell>
                  <TableCell>更新日時</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.main.map((row: any, i: number) => (
                  <TableRow key={row.award}>
                    <TableCell component="th" scope="row">
                      <a
                        href={`//mincap${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/#p-${row.project_id}`}
                        target="_blank"
                      >
                        {row.project_name}
                      </a>
                    </TableCell>
                    <TableCell scope="row">
                      <a href={row.share_url} target="_blank">
                        {row.share_url}
                      </a>
                    </TableCell>
                    <TableCell>{row.screening != '' && <span style={{fontWeight:'bold', color: row.screening == 'OK' ? "green":"red"}}>[{row.screening}]&nbsp;</span>}{row.body}</TableCell>
                    <TableCell>
                      {[]
                        .concat(row.themes ? JSON.parse(row.themes) : [])
                        .concat(row.partners ? JSON.parse(row.partners) : [])
                        .join(" / ")}
                    </TableCell>
                    <TableCell>{row.industry}</TableCell>
                    <TableCell>
                      {([] as string[])
                        .concat(
                          row.devices !== undefined
                            ? JSON.parse(row.devices)
                            : []
                        )
                        .concat(
                          row.devices_other !== undefined
                            ? [row.device_other]
                            : []
                        )
                        .join(" / ")}
                    </TableCell>
                    <TableCell>
                      {([] as string[])
                        .concat(
                          row.apps !== undefined ? JSON.parse(row.apps) : []
                        )
                        .concat(
                          row.apps_other !== undefined ? [row.apps_other] : []
                        )
                        .join(" / ")}
                    </TableCell>
                    <TableCell>{row.opendata ? "○" : ""}</TableCell>
                    <TableCell>
                      {new Date(row.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.updated_at).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default PresentAward;
