// https://stackoverflow.com/questions/50724915/is-it-possible-to-have-multiple-dataproviders-in-react-admin

import {
    addTreeMethodsBasedOnParentAndPosition, // getTree と小文字 GET_LIST などは大文字
    ADD_CHILD_NODE,
    ADD_ROOT_NODE,
    GET_CHILD_NODES, GET_TREE, MOVE_AS_NTH_CHILD_OF,
    MOVE_AS_NTH_SIBLING_OF
} from '@react-admin/ra-tree-project-assets';
import { DataProvider, LegacyDataProvider } from 'ra-core';
import {
    CREATE, DELETE,
    DELETE_MANY, GET_LIST, GET_MANY,
    GET_MANY_REFERENCE, GET_ONE, UPDATE,
    UPDATE_MANY
} from 'react-admin';
import hasuraDataProvider from './hasuraDataProvider';
// import storageDataProvider from './storageDataProvider';

interface StringKeyObject {
    [key: string]: any;
}

interface dataProvidersObject {
    dataProvider: DataProvider | LegacyDataProvider | null,
    resources: string[]
}

const dataProviders: dataProvidersObject[] = [
    // for graphql
    {
        dataProvider: null,
        resources: ['projects', 'assets', 'taxons', 'assets_taxons', 'processes', 'mincap_entries']
    },
    // for minio << TODO: 大改造が必要 点群 (las) のときにとくに
    {
        dataProvider: null,
        resources: ['assets_files']
    },
    // for tree
    {
        dataProvider: null,
        resources: ['projects_assets']
    },
];

const returnDataProviders: LegacyDataProvider = async (type: string, resource: string, params: any) => {
    const mappingType: StringKeyObject = {
        [GET_LIST]: 'getList',
        [GET_ONE]: 'getOne',
        [GET_MANY]: 'getMany',
        [GET_MANY_REFERENCE]: 'getManyReference',
        [CREATE]: 'create',
        [UPDATE]: 'update',
        [UPDATE_MANY]: 'updateMany',
        [DELETE]: 'delete',
        [DELETE_MANY]: 'deleteMany',
        [GET_TREE]: 'getTree',              // tree only
        [ADD_ROOT_NODE]: 'addRootNode',     // tree only
        [ADD_CHILD_NODE]: 'addChildNode',   // tree only
        [GET_CHILD_NODES]: 'getChildNodes',  // tree only
        ['deleteBranch']: 'deleteBranch',
        [MOVE_AS_NTH_CHILD_OF]: 'moveAsNthChildOf',
        [MOVE_AS_NTH_SIBLING_OF]: 'moveAsNthSiblingOf',
    };

    // console.log('mappingType', resource, type);

    const dataProviderMapping = dataProviders.find((dp) => dp.resources.includes(resource));

    if (typeof dataProviderMapping?.dataProvider === 'function') {
        // hasura は現時点では LegacyDataProvider 
        // 2021.12 ra-data-hasura を fork したら Proxy になり
        // ↓このパッチを当てたら、DataProvider に
        // https://github.com/hasura/ra-data-hasura/pull/72/commits/2905dfb1d3a1140a26830805f59ebad3cea86211
        return dataProviderMapping.dataProvider(type, 'tomap_' + resource, params);
    } else {
        // 2021.12 ra-data-hasura を fork したら Proxy になり hasura も minio もこっちをとおる
        // TODO: リファクタリング
        return dataProviderMapping?.dataProvider![mappingType[type]]('tomap_' + resource, params);
    }
};

// TODO: run Once でもよいはず。もっとシンプルにかけそう。
const buildDataProviders = async (keycloak: any) => {
    dataProviders[0].dataProvider = await hasuraDataProvider(keycloak);
    dataProviders[1].dataProvider = null; // storageDataProvider();
    // TODO: recursive な view でもいいのか？（CRUD 対応だからダメだがすこし改善したい）
    dataProviders[2].dataProvider = addTreeMethodsBasedOnParentAndPosition(
        dataProviders[0].dataProvider as DataProvider
        , 'parent_id', 'position', false);
    return returnDataProviders;
};

export default buildDataProviders;