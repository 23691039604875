import React from 'react';

// https://github.com/microsoft/TypeScript/issues/28938
// interface WithMetaProps {
//     metaFields: string[]
// }

//props: EditProps & { children: ReactElement }

// TODO: children が嫌がっているのか TypeScriptエラーになる。いったん any で。。。あとできちんと直そう。
// const withMeta = <P extends {}>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
const withMeta = (WrappedComponent: any, decodeMeta: (record: object) => void) => {
    return (props: any) => {
        const { children, ...otherProps } = props;

        decodeMeta(props.record);

        return (<WrappedComponent {...otherProps} >
            {children}
        </WrappedComponent>);

    };
};

export default withMeta;

// export function withMeta<P>(WrappedComponent: React.ComponentType<P & WithMetaProps>) {
//     return (props: P) => {
//         // const {children, ...otherProps} = props;

//         return (<WrappedComponent {...props} />)
//     };

// };
