/* eslint-disable no-use-before-define */
import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDataProvider, Identifier, useInput } from 'react-admin';

const filter = createFilterOptions<string | TaxonType>();

// TODO: props
type FreeSoloCreateOptionDialogProps = {
    source: string;
}
const FreeSoloCreateOptionDialog: React.FC<FreeSoloCreateOptionDialogProps> = (props) => {
    const [open, toggleOpen] = React.useState(false);
    const [taxons, setTaxons] = React.useState<(string | TaxonType)[]>([]);
    const [values, setValues] = React.useState<(string | TaxonType)[]>([]);
    const [initialized, setInitialized] = React.useState(false);
    const dataProvider = useDataProvider();
    const refInitValue = useRef(0);

    const {
        input: { onChange, value },
    } = useInput(props);
    // 初期化
    useEffect(() => {
        (async () => {
            const taxonsList = await dataProvider.getList('taxons',
                {
                    pagination: { page: 1, perPage: 999 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {}
                }
            );

            const taxons: TaxonType[] = [];
            for (const k in taxonsList.data) {
                const t = taxonsList.data[k];
                taxons.push({ id: t.id, name: t.name });
            }
            setTaxons(taxons);

            // value の初期化。しなくてよい方法もあるけど、AddNew 展開がめんどうなので、ここで変換してあとで戻す。
            if (!initialized) {
                try {
                    const values = value.map((v: any) => taxons.filter((w) => w.id?.toString() === v)[0]);
                    setValues(values);
                } catch {
                    setValues([]);
                }
                setInitialized(true);
            }
        })()
    }, [values])

    useEffect(() => {
        // 1度目は初期処理。2度目は ↑ の useEffect より。そこでは、onChange がまだ動いて欲しくない。
        if (refInitValue.current < 2) {
            refInitValue.current++;
            return;
        }

        // id のみの配列を返そう
        const value = values.map((v) => typeof v === 'string' ? v : v.id);
        onChange(value);
    }, [values, onChange])

    const handleClose = () => {
        setDialogValue({
            name: '',
        });
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        name: '',
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { data } = await dataProvider.create('taxons', { data: { name: dialogValue.name } });
        setValues(values.concat({ id: data.id, name: data.name }))

        handleClose();
    };

    return (
        <React.Fragment>
            <Autocomplete
                multiple
                value={values}
                onChange={(event, newValues, reason) => {
                    switch (reason) {
                        case 'select-option':
                            const newValue = newValues.slice(-1)[0];
                            if (typeof newValue === 'string') {
                                // timeout to avoid instant validation of the dialog's form.
                                setTimeout(() => {
                                    toggleOpen(true);
                                    setDialogValue({
                                        name: newValue,
                                    });
                                });
                            } else if (newValue && newValue.inputValue) {
                                toggleOpen(true);
                                setDialogValue({
                                    name: newValue.inputValue,
                                });
                            } else {
                                setValues(values.concat(newValue));
                            }
                            break;
                        case 'remove-option':
                            setValues(newValues);
                            break;
                        case 'clear':
                            setValues([]);
                            break;
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params) as TaxonType[];

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id="free-solo-dialog-demo"
                options={taxons}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => typeof (option) === 'string' ? option : option.name}
                style={{ width: 400 }}
                freeSolo={values.length >= 5 ? false : true}
                getOptionDisabled={(options)=>(values.length >= 5 ? true : false)}
                renderInput={(params) => (
                    <>
                        <TextField {...params} label="共有タグ" variant="filled" placeholder="" helperText="5つまで。運営が予告なく整理整頓する場合があります。" />
                        <p></p>
                    </>
                )}
            // {...rest}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">Add a new film</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you miss any film in our list? Please, add it!
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                            label="title"
                            type="text"
                        />
                        {/* <TextField
                            margin="dense"
                            id="name"
                            value={dialogValue.year}
                            onChange={(event) => setDialogValue({ ...dialogValue, year: event.target.value })}
                            label="year"
                            type="number"
                        /> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

interface TaxonType {
    inputValue?: string;
    id?: Identifier;
    name: string;
}

export default FreeSoloCreateOptionDialog;