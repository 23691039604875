// import { DataProvider, Record } from 'ra-core';

import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
import buildHasuraProvider from "ra-data-hasura"; // javascript だった
// import { ApolloClient, InMemoryCache } from '@apollo/client';
// TODO: 差分でのアップデートが必要？
// https://github.com/worklifebeyond/wlb-ra-data-hasura

// alias
// import { LegacyDataProvider } from 'ra-core';

// // export default MinioDataProvider;
const hasuraDataProvider = async (keycloak: any) => {
  const authLink = setContext((_, { headers }) => {
    // データにアクセスするたびに、毎回よばれる
    // console.log('setContext', keycloak.token);
    return {
      headers: {
        ...headers,
        // 'x-hasura-admin-secret': 'hasuraAdminSecret',
        Authorization: `Bearer ${keycloak.token}`,
      },
    };
  });
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
  });

  const clientOptionsWithAuth = {
    link: authLink.concat(httpLink),
  };

  const dataProvider = await buildHasuraProvider({
    clientOptions: clientOptionsWithAuth,
  });

  return dataProvider;

  // alias っぽい動きをここでついかするなら
  // const hasuraDataProviderWrapper : LegacyDataProvider  = (type: string, resource: string, params: any) => {
  //     return dataProvider(type, `tomap_${resource}`, params);
  // }
  // return hasuraDataProviderWrapper;
};
export default hasuraDataProvider;

// useEffect(() => {
//   console.log('App.tsx keycloak onAuthRefreshSuccess 2', keycloakToken);
//   const buildDataProvider = async () => {
//       // const cache = new InMemoryCache();
//       // // ({
//       // //   typePolicies: {
//       // //     Query: {
//       // //       fields: {
//       // //         tomap_projects: {
//       // //           merge(existing, incoming) {
//       // //             console.log("tomap_projects", JSON.stringify(existing), JSON.stringify(incoming));
//       // //             return incoming
//       // //           }
//       // //         },
//       // //         tomap_categories: {
//       // //           merge(existing, incoming) {
//       // //             console.log("tomap_categories", existing, incoming);
//       // //             return incoming
//       // //           }
//       // //         }
//       // //       }
//       // //     }
//       // //   }
//       // // })

//       // // const authLink = setContext((_, { headers }) => {
//       // //   return {
//       // //     headers: {
//       // //       ...headers,
//       // //       authorization: 'Bearer ' + keycloak.token, // however you get your token
//       // //     }
//       // //   }
//       // // });

//       // console.log('appolo', keycloakToken);
//       // const myClient = new ApolloClient({
//       //   cache: cache,
//       //   uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
//       //   // link: authLink.concat(httpLink),
//       //   headers: {
//       //     // 'x-hasura-admin-secret': 'foobarbaz',
//       //     'Authorization': 'Bearer ' + keycloakToken,
//       //   },
//       // });

//       // const customBuildFields = (type: any, fetchType: string) => {
//       //   const resourceName = type.name;

//       //   console.log("customBuildFields", resourceName, fetchType);
//       //   if (resourceName === 'tomap_projects' && fetchType === 'GET_ONE') {
//       //     return extractFieldsFromQuery(GET_ONE_PROJECT);
//       //   }

//       //   // No custom query defined, so use the default query fields (all, but no related/nested).
//       //   console.log(buildFields(type, fetchType);
//       //   return buildFields(type, fetchType);
//       // };

//       // const dataProvider = await buildHasuraProvider({ client: myClient }, { buildFields: customBuildFields });

//       // const dataProvider = await buildHasuraProvider({ client: myClient });

//       const authLink = setContext((_, { headers }) => ({
//         headers: {
//           ...headers,
//           // 'x-hasura-admin-secret': 'hasuraAdminSecret',
//           'Authorization': `Bearer ${keycloakToken}`,
//         },
//       }));

//       const httpLink = createHttpLink({
//         uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
//       });

//       const clientOptionsWithAuth = {
//         link: authLink.concat(httpLink),
//       };

//       const dataProvider = await buildHasuraProvider({ client: clientOptionsWithAuth });

//       setDataProvider(() => dataProvider);
//   };
//   buildDataProvider();

// }, [keycloakToken]);   // 要確認
