import {
  Tooltip,
  Box,
  Button as MuButton,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Slider,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import * as Cesium from "cesium";
import {
  Cartesian3,
  Cesium3DTileset as CesiumCesium3DTileset,
  Cesium3DTileStyle,
  Entity as CesiumEntity,
  Ion,
  Model as CesiumModel,
  ScreenSpaceEventType,
  Viewer as CesiumViewer,
  UrlTemplateImageryProvider,
} from "cesium";
import GsiTerrainProvider from "cesium-gsi-terrain";
import HeadingPitchRoll from "cesium/Source/Core/HeadingPitchRoll";
import TerrainProvider from "cesium/Source/Core/TerrainProvider";
import ShadowMode from "cesium/Source/Scene/ShadowMode";
import { isArray } from "lodash";
import { getgid } from "process";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  EditProps,
  NumberInput,
  useGetIdentity,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
  Labeled,
} from "react-admin";
import { useForm } from "react-final-form";
import {
  Cesium3DTileset,
  CesiumComponentRef,
  Entity,
  ImageryLayer,
  Model,
  ModelGraphics,
  PlaneGraphics,
  PolylineGraphics,
  Scene,
  ScreenSpaceCameraController,
  ScreenSpaceEvent,
  ScreenSpaceEventHandler,
  Viewer,
} from "resium";
import { getElevation } from "../utils/getElevation";
import HelpIcon from "@material-ui/icons/Help";
import { group } from "console";

// TODO: 再描画のパフォーマンス向上
// 参考） https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/
// useMemo 系で対応できる可能性もある

// adjust
// https://sandcastle.cesium.com/index.html?src=3D%20Tiles%20Adjust%20Height.html
// https://github.com/CesiumGS/cesium/issues/8632

// Cesium.Bing もやめて国土地理院にしてみた
Ion.defaultAccessToken = "YOUR_ACCESS_TOKEN";

// TODO: tomap-view の config の terrain url の共有？
// const terrainProvider = new CesiumTerrainProvider({
//   url: "https://tomap-3dtiles.s3.ap-northeast-1.amazonaws.com/terrain/tokyo23_5mg",
//   // url: process.env.REACT_APP_TERRAIN_URL!,
//   requestVertexNormals: false,
//   credit: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院 地理院タイル</a>を利用しています。"
// });

// const bingMapsImageryProvider = new BingMapsImageryProvider({
//   url: "https://dev.virtualearth.net",
//   key: process.env.REACT_APP_BING_API_KEY!,
//   // mapStyle : Cesium.BingMapsStyle.AERIAL
// });

// 二重かできないのか // use memo あるいは use effect ?
const seamlessphotoImageryProvider = new Cesium.UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
  // url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
  maximumLevel: 18,
});
const plateauOrthoImageryProvider = new Cesium.UrlTemplateImageryProvider({
  url: "https://gic-plateau.s3.ap-northeast-1.amazonaws.com/2020/ortho/tiles/{z}/{x}/{y}.png",
  maximumLevel: 19,
});
const gsiStdImageryProvider = new Cesium.UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
  maximumLevel: 18,
});

Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5N2UyMjcwOS00MDY1LTQxYjEtYjZjMy00YTU0ZTg5MmViYWQiLCJpZCI6ODAzMDYsImlhdCI6MTY0Mjc0ODI2MX0.dkwAL1CcljUV7NA7fDbhXXnmyZQU_c-G5zRx8PtEcxE";
const terrainProvider = new Cesium.CesiumTerrainProvider({
  url: Cesium.IonResource.fromAssetId(770371),
});

let boundingSphereCenter: Cartesian3 | undefined = undefined;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// 3次元の角度
// https://mathwords.net/bekutorunasukaku
// http://www5d.biglobe.ne.jp/~noocyte/Programming/Geometry/RotationDirection.html#GetAngleAndDirection3
// o 原点 s 開始位置 e 終了位置
// http://www5d.biglobe.ne.jp/~noocyte/Programming/Geometry/RotationDirection.html
function nasukaku(
  o: Cartesian3,
  s: Cartesian3,
  e: Cartesian3,
  startHpr: HeadingPitchRoll,
  axis: string
): number {
  // 右回りか、左回りかの判断のために、回転面と垂直とドラッグ開始位置での基準面をつくる
  // const localConvert = Cesium.Transforms.eastNorthUpToFixedFrame(o);
  const localConvert = Cesium.Transforms.headingPitchRollToFixedFrame(
    o,
    startHpr
  ); // 事前に作ろう
  const localConvertInverse = Cesium.Matrix4.inverse(
    localConvert,
    new Cesium.Matrix4()
  );
  const sl = Cesium.Matrix4.multiplyByPoint(
    localConvertInverse,
    s,
    new Cesium.Cartesian3()
  );
  const el = Cesium.Matrix4.multiplyByPoint(
    localConvertInverse,
    e,
    new Cesium.Cartesian3()
  );
  var r = Cesium.Cartesian3.angleBetween(sl, el);

  // 基準軸が上か下か
  const cross = Cesium.Cartesian3.cross(sl, el, new Cesium.Cartesian3());
  return r * Math.sign((cross as any)[axis]);
}

type TypeMinimumPixelSize = {
  [key: string]: number;
};

const minimumPixelSize: TypeMinimumPixelSize = {
  move: 128,
  rotate: 256,
  scale: 128,
  terrain: 32,
};

const dummyCredit = document.createElement("div");

// TODO: component化 https://github.com/marmelab/react-admin/issues/4349
interface TooltipWrapperProps {
  children: React.ReactElement;
}
const TooltipWrapper = React.forwardRef<HTMLDivElement, TooltipWrapperProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref}>{React.cloneElement(children, props)}</div>
  )
);

const Adjust: FC<EditProps> = (props: any) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const ref = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const tilesetRef = useRef<CesiumComponentRef<CesiumCesium3DTileset>>(null);
  const modelRef = useRef<CesiumComponentRef<CesiumModel>>(null);
  // const [meta, setMeta] = useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [rendorError, setRendorError] = useState(false);
  const [pointSize, setPointSize] = useState(3);
  const { keycloak } = useKeycloak();
  const tokenParsed = keycloak.tokenParsed as any;

  const [imagery, setImagery] = useState("plateau");
  const gizmoCenterRef = useRef<CesiumComponentRef<CesiumEntity>>(null);
  const gizmoPlaneRef = useRef<CesiumComponentRef<CesiumEntity>>(null);

  const [plateauCatalog, setPlateauCatalog] = useState<any[]>([]);

  // const [terrainProvider, setTerrainProvider] = useState<TerrainProvider>();

  type TypeGizmoRef = {
    [key: string]: React.RefObject<CesiumComponentRef<CesiumEntity>>;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gizmoRef = {
    z: useRef<CesiumComponentRef<CesiumEntity>>(null),
    y: useRef<CesiumComponentRef<CesiumEntity>>(null),
    x: useRef<CesiumComponentRef<CesiumEntity>>(null),
  } as TypeGizmoRef;

  const form = useForm();

  // const [boundingSphereCenter, setBoundingSphereCenter] = useState<Cartesian3 | null>(null);
  // const targetRef = useRef<CesiumComponentRef<Cesium3DTilesetProps>>(null);
  const record = useRecordContext();

  // console.log("adjust props", JSON.stringify(props));

  const [positionCenter, setPositionCenter] = useState(
    new Cartesian3(1, 0, 0) // all zero だと lon lat が算出できず、Gizmo初期位置が計算できずエラー発生
  );

  const [offset, setOffset] = useState({
    longitude: 0,
    latitude: 0,
    height: 0,
    heading: 0,
    pitch: 0,
    roll: 0,
    scale: 1,
  });

  const [gizmo, setGizmo] = useState({
    mode: "rotate", // default は move がいいかな
    active: false,
    dragging: false,
    axis: "z", // "z", "y", "x"  fixme: enum
    start: new Cesium.Cartesian3(),
    end: new Cesium.Cartesian3(),
    startHpr: new Cesium.HeadingPitchRoll(),
    startCartographic: new Cesium.Cartographic(),
    startCartographicOffsetRadians: { longitude: 0, latitude: 0, height: 0 },
  });

  // useEffect(() => {
  //   (async () => {
  //     // 地面を独自計算
  //     // setTerrainProvider(await GsiTerrainProvider.factory({}));
  //     // 地面を PLATEAU に
  //   })();
  // }, []);

  // TODO: エラー値は、Record に持たせて保存すべきかもしれない。
  useEffect(() => {
    setRendorError(false);
  }, [record]);

  // Plateau
  useEffect(() => {
    // TODO：なぜか初期2回呼ばれる。対応したい。
    (async () => {
      const response = await fetch(
        `https://mincap${process.env.REACT_APP_VIEW_SERVER_DOMAIN}/init/tomap-plateau.json`
      );
      const catalog = await response.json();
      const result: any[] = [];

      // かるく再帰処理
      const recursive = (node: any) => {
        if (Array.isArray(node)) {
          node.map(recursive);
        } else {
          if (node.type == "group") {
            recursive(node.members);
          } else {
            result.push({ id: node.id, name: node.name, url: node.url });
          }
        }
      };
      recursive(catalog.catalog);
      setPlateauCatalog(result);
    })();
  }, []);

  useEffect(() => {
    if (offset) {
      //   console.log('offset', offset);
      form.change("offset", offset);
    }

    // Model
    // var origin = Cesium.Cartesian3.fromDegrees(-95.0, 40.0, 200000.0);
    // m.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(origin);
    if (tilesetRef.current?.cesiumElement) {
      const c = tilesetRef.current?.cesiumElement;

      if (c.ready && boundingSphereCenter) {
        var CenterFrom = boundingSphereCenter;
        // var CenterFrom = Cesium.Cartesian3.fromDegrees(0, 0, 0);
        var CenterTo = Cesium.Cartesian3.fromDegrees(
          offset.longitude,
          offset.latitude,
          offset.height
        );

        var result = Cesium.Matrix4.ZERO.clone();

        // Z-X-Y オイラー角? Z が上でいいのか？ Z が上で青。
        // pitch(X) / roll(y) / heading (z) 順で回転させると手になじむ？
        // Roll は、実物に則する

        // heading は絶対水平に上から見てぐるぐるまわる
        //
        // 先に回転を整理整頓しよう
        var rotationF = Cesium.Transforms.headingPitchRollQuaternion(
          CenterFrom,
          Cesium.HeadingPitchRoll.fromDegrees(0, 0, 0)
        );

        var rotationT = Cesium.Transforms.headingPitchRollQuaternion(
          CenterTo,
          Cesium.HeadingPitchRoll.fromDegrees(
            offset.heading,
            offset.pitch,
            offset.roll
          )
        );

        // 反転して乗算
        Cesium.Quaternion.inverse(rotationF, rotationF);
        Cesium.Quaternion.multiply(rotationT, rotationF, rotationF);

        var m = Cesium.Matrix4.fromTranslationRotationScale(
          new Cesium.TranslationRotationScale(
            Cesium.Cartesian3.ZERO,
            rotationF,
            new Cesium.Cartesian3(1.0, 1.0, 1.0) // ここで大きくしてもいけない
          )
        );

        var m1 = Cesium.Matrix4.fromTranslationQuaternionRotationScale(
          new Cesium.Cartesian3(-CenterFrom.x, -CenterFrom.y, -CenterFrom.z),
          Cesium.Quaternion.IDENTITY,
          new Cesium.Cartesian3(1.0, 1.0, 1.0) // ここで大きくしてもいけない
        );

        Cesium.Matrix4.multiplyTransformation(m, m1, result);

        var m3 = Cesium.Matrix4.fromTranslationQuaternionRotationScale(
          CenterTo,
          Cesium.Quaternion.IDENTITY,
          new Cesium.Cartesian3(offset.scale, offset.scale, offset.scale) // ここで大きくしてもよさそう
        );
        Cesium.Matrix4.multiplyTransformation(m3, result, result);
        c.modelMatrix = result;

        // 3DCapture は root の transform が空だけど、PointCloud は初期値が入ってるから乗算

        Cesium.Matrix4.multiply(result, c.root.transform, result);

        form.change("transform", Cesium.Matrix4.toArray(result));

        setPositionCenter(CenterTo);
      }
    }

    if (modelRef.current?.cesiumElement) {
      setPositionCenter(
        Cesium.Cartesian3.fromDegrees(
          offset.longitude,
          offset.latitude,
          offset.height
        )
      );
    }

    // 再描画処理をここに？
    ref.current?.cesiumElement?.scene?.requestRender();
  }, [offset, form, pointSize]); // form は無視してもいい。Lint が警告だすから放り込んでみているだけ

  useEffect(() => {
    if (ref.current?.cesiumElement?.scene) {
      if (gizmo.mode === "terrain") {
        ref.current.cesiumElement.scene.globe.depthTestAgainstTerrain = true;
      } else {
        ref.current.cesiumElement.scene.globe.depthTestAgainstTerrain = false;
      }
    }
  }, [gizmo.mode]);

  const handleChangeGizmoMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string | null
  ) => {
    if (newMode) {
      setGizmo({ ...gizmo, mode: newMode });
    }
  };

  const handleDialogClose = (yn: boolean) => {
    if (yn) {
      currentPosition();
    }
    setDialogOpen(false);
  };

  const lookAt = () => {
    // Model は Entity じゃない？？？
    if (gizmoCenterRef.current?.cesiumElement && ref.current?.cesiumElement) {
      ref.current.cesiumElement.zoomTo(
        gizmoCenterRef.current?.cesiumElement,
        new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-25), 0)
      );
    }
  };

  const resetPosition = () => {
    if (boundingSphereCenter) {
      if (tilesetRef.current?.cesiumElement) {
        console.log("boundingSphereCenter", boundingSphereCenter);
        const c2: Cesium.Cartographic =
          Cesium.Cartographic.fromCartesian(boundingSphereCenter);
        setOffset({
          latitude: Cesium.Math.toDegrees(c2.latitude),
          longitude: Cesium.Math.toDegrees(c2.longitude),
          height: c2.height,
          heading: 0,
          pitch: 0,
          roll: 0,
          scale: 1,
        });
        lookAt();
      }
    }

    if (modelRef.current?.cesiumElement) {
      setOffset({
        latitude: 35.681167, // いったん東京駅
        longitude: 139.767052,
        height: 42,
        heading: 0,
        pitch: 0,
        roll: 0,
        scale: 1,
      });
      lookAt();
    }
  };

  // TODO: sampleTerrainMostDetailed を 二回よんだり待ったりしないと高さが算出されないときがあるよ。
  const currentPosition = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const crd = pos.coords;
      if (terrainProvider) {
        Cesium.sampleTerrainMostDetailed(terrainProvider, [
          Cesium.Cartographic.fromDegrees(crd.longitude, crd.latitude),
        ]).then((cs) => {
          const c = cs[0];
          setOffset({
            ...offset,
            latitude: crd.latitude,
            longitude: crd.longitude,
            height: c.height,
          });
          setTimeout(lookAt);
          // setTimeout(() => {
          //   Cesium.sampleTerrainMostDetailed(terrainProvider,
          //     [Cesium.Cartographic.fromDegrees(crd.longitude, crd.latitude)])
          //     .then(
          //       (cs) => {
          //         const c = cs[0];
          //         setOffset({
          //           ...offset,
          //           latitude: crd.latitude,
          //           longitude: crd.longitude,
          //           height: c.height
          //         });
          //         setTimeout(lookAt);
          //       }
          //     )
          // }, 500)
        });
      }
    });
  };

  const addGeoidHeight = async () => {
    const elevation = await getElevation({
      lon: offset.longitude,
      lat: offset.latitude,
    });
    setOffset({ ...offset, height: offset.height + elevation.geoidHeight });
  };

  // imageryProviderViewModels={[]} terrainProviderViewModels={[]} これをすると描写がおかしくなった
  if (identityLoading && terrainProvider) {
    return <div>loading...</div>;
  }

  if (rendorError) {
    return (
      <div>
        Asset
        の読み込みに失敗しました。アップロードしたデータに間違いがあるか、コンバートに失敗している可能性があります。
      </div>
    );
  } else {
    return (
      <>
        <Card>
          <Viewer
            ref={ref}
            baseLayerPicker={false}
            style={{ height: "480px" }}
            skyBox={false}
            scene3DOnly={true}
            requestRenderMode={true}
            maximumRenderTimeChange={Infinity}
            terrainShadows={ShadowMode.DISABLED}
            timeline={false}
            animation={false}
            terrainProvider={terrainProvider}
            // imageryProvider={seamlessphotoImageryProvider}
            // onClick={handleClick}
            navigationHelpButton={false}
            homeButton={false}
            sceneModePicker={false}
            geocoder={false}
            infoBox={false}
            selectionIndicator={false}
            shouldAnimate={true}
            creditContainer={dummyCredit}
            // contextOptions={{allowTextureFilterAnisotropic: false}}
          >
            {/* // Back layer */}
            {/* これは Viewer の初期化で読んでみた でないと標準の ionId = 2 をみにいったりする <ImageryLayer imageryProvider={seamlessphotoImageryProvider} /> */}
            {imagery == "plateau" ? (
              <>
                <ImageryLayer imageryProvider={seamlessphotoImageryProvider} />
                <ImageryLayer imageryProvider={plateauOrthoImageryProvider} />
              </>
            ) : (
              <ImageryLayer imageryProvider={gsiStdImageryProvider} />
            )}
            {/* // Front layer */}

            <Scene
              onRenderError={() => {
                setRendorError(true);
              }}
            />
            <ScreenSpaceCameraController enableCollisionDetection={true} />

            <ScreenSpaceEventHandler>
              <ScreenSpaceEvent
                action={(movement: any) => {
                  // do nothing
                  if (ref.current?.cesiumElement) {
                    ref.current.cesiumElement.trackedEntity = undefined;
                  }
                }}
                type={ScreenSpaceEventType.LEFT_DOUBLE_CLICK}
              />

              <ScreenSpaceEvent
                action={(movement: any) => {
                  if (gizmo.mode === "terrain" && movement.position) {
                    const p = ref.current!.cesiumElement!.scene.pickPosition(
                      movement.position
                    );
                    if (p) {
                      const ll = Cesium.Cartographic.fromCartesian(p);
                      setOffset({
                        ...offset,
                        latitude: Cesium.Math.toDegrees(ll.latitude),
                        longitude: Cesium.Math.toDegrees(ll.longitude),
                        height: ll.height > 0 ? ll.height : 0,
                      });
                    }
                  }
                }}
                type={ScreenSpaceEventType.LEFT_CLICK}
              />

              <ScreenSpaceEvent
                action={(movement: any) => {
                  if (gizmo.mode !== "terrain") {
                    if (gizmo.active) {
                      ref.current!.cesiumElement!.scene.screenSpaceCameraController.enableRotate =
                        false;
                      let p: Cartesian3 | undefined = undefined;
                      const ray = ref.current?.cesiumElement?.camera.getPickRay(
                        movement.position
                      );
                      // HACK: private method を無理やり読んでいる
                      // var pickedObjects2 = ref.current?.cesiumElement?.scene.drillPickFromRay(ray);
                      const pickedFromRayObjects = (
                        ref.current?.cesiumElement?.scene as any
                      ).drillPickFromRay(ray);
                      for (var j = 0; j < pickedFromRayObjects.length; ++j) {
                        const pickedObject = pickedFromRayObjects[j];
                        if (
                          pickedObject.object &&
                          pickedObject.object.id ===
                            gizmoPlaneRef.current?.cesiumElement
                        ) {
                          p = pickedObject.position;
                          break;
                        }
                      }
                      if (p) {
                        const startPositionCartographic =
                          Cesium.Cartographic.fromCartesian(p);
                        setGizmo({
                          ...gizmo,
                          dragging: true,
                          start: p,
                          end: p,
                          startHpr: Cesium.HeadingPitchRoll.fromDegrees(
                            offset.heading,
                            offset.pitch,
                            offset.roll
                          ),
                          startCartographic: Cesium.Cartographic.fromDegrees(
                            offset.longitude,
                            offset.latitude,
                            offset.height
                          ),
                          startCartographicOffsetRadians: {
                            longitude:
                              gizmo.axis === "x"
                                ? startPositionCartographic.longitude -
                                  Cesium.Math.toRadians(offset.longitude)
                                : 0,
                            latitude:
                              gizmo.axis === "y"
                                ? startPositionCartographic.latitude -
                                  Cesium.Math.toRadians(offset.latitude)
                                : 0,
                            height:
                              gizmo.axis === "z"
                                ? startPositionCartographic.height -
                                  offset.height
                                : 0,
                          },
                        });
                      }
                    }
                  }
                }}
                type={ScreenSpaceEventType.LEFT_DOWN}
              />

              <ScreenSpaceEvent
                action={(movement) => {
                  if (gizmo.active) {
                    // console.log(movement.position);
                    // var ray = viewer.camera.getPickRay(movement.position);
                    // var c3 = Cesium.IntersectionTests.rayPlane(ray, gizmoYplane);
                    // console.log(c3);
                    if (gizmo.dragging) {
                      setGizmo({ ...gizmo, dragging: false });
                      setTimeout(function () {
                        ref.current!.cesiumElement!.scene.screenSpaceCameraController.enableRotate =
                          true;
                      }, 300);
                    }
                  }
                }}
                type={ScreenSpaceEventType.LEFT_UP}
              />

              <ScreenSpaceEvent
                action={(e) => {
                  if (gizmo.mode !== "terrain") {
                    const movement = e as unknown as {
                      startPosition: Cesium.Cartesian2;
                      endPosition: Cesium.Cartesian2;
                    };

                    if (gizmo.dragging) {
                      let p: Cartesian3 | undefined = undefined;
                      const ray = ref.current?.cesiumElement?.camera.getPickRay(
                        movement.endPosition
                      );
                      // HACK: private method を無理やり読んでいる
                      // var pickedObjects2 = ref.current?.cesiumElement?.scene.drillPickFromRay(ray);
                      const pickedFromRayObjects = (
                        ref.current?.cesiumElement?.scene as any
                      ).drillPickFromRay(ray);
                      for (var j = 0; j < pickedFromRayObjects.length; ++j) {
                        const pickedObject = pickedFromRayObjects[j];
                        if (
                          pickedObject.object &&
                          pickedObject.object.id &&
                          pickedObject.object.id ===
                            gizmoPlaneRef.current?.cesiumElement
                        ) {
                          p = pickedObject.position;
                          break;
                        }
                      }
                      if (p) {
                        if (gizmo.mode === "rotate") {
                          const r = nasukaku(
                            positionCenter,
                            gizmo.start,
                            p,
                            gizmo.startHpr,
                            gizmo.axis
                          );
                          if (r) {
                            if (gizmo.mode === "rotate") {
                              const qEnd =
                                Cesium.Quaternion.fromHeadingPitchRoll(
                                  new Cesium.HeadingPitchRoll(
                                    gizmo.axis === "z" ? -r : 0,
                                    gizmo.axis === "y" ? -r : 0,
                                    gizmo.axis === "x" ? r : 0
                                  )
                                );
                              const qStart =
                                Cesium.Quaternion.fromHeadingPitchRoll(
                                  gizmo.startHpr
                                );
                              const q = Cesium.Quaternion.multiply(
                                qStart,
                                qEnd,
                                new Cesium.Quaternion()
                              );
                              const hpr =
                                Cesium.HeadingPitchRoll.fromQuaternion(q);
                              setOffset({
                                ...offset,
                                heading: Cesium.Math.toDegrees(hpr.heading),
                                pitch: Cesium.Math.toDegrees(hpr.pitch),
                                roll: Cesium.Math.toDegrees(hpr.roll),
                              });
                            }
                          }
                          setGizmo({ ...gizmo, end: p });
                        }

                        // TODO: 楕円体と平行に、湾曲も考慮すべきかもしれないが、誤差でしょう。
                        if (gizmo.mode === "move") {
                          const m = Cesium.Cartographic.fromCartesian(p);
                          const n = gizmo.startCartographic.clone();

                          switch (gizmo.axis) {
                            case "z": // height
                              n.height =
                                m.height -
                                gizmo.startCartographicOffsetRadians.height;
                              break;
                            case "y": // latitude
                              n.latitude =
                                m.latitude -
                                gizmo.startCartographicOffsetRadians.latitude;
                              break;
                            case "x": // longtitude
                              n.longitude =
                                m.longitude -
                                gizmo.startCartographicOffsetRadians.longitude;
                              break;
                          }

                          setOffset({
                            ...offset,
                            longitude: Cesium.Math.toDegrees(n.longitude),
                            latitude: Cesium.Math.toDegrees(n.latitude),
                            height: n.height,
                          });
                          // setGizmo({ ...gizmo});
                        }
                      }
                    } else {
                      // FIXME: https://github.com/reearth/resium/issues/484
                      const pickedObjects =
                        ref.current!.cesiumElement!.scene.drillPick(
                          movement.endPosition,
                          100,
                          5,
                          5
                        );

                      // console.log(pickedObjects);
                      let gizmoAxis = "";

                      gizmo_loop: for (
                        var i = 0;
                        i < pickedObjects.length;
                        ++i
                      ) {
                        const p = pickedObjects[i];

                        if (p.id) {
                          for (const axis in gizmoRef) {
                            if (
                              p.id === gizmoRef[axis]!.current?.cesiumElement
                            ) {
                              // console.log(axis)
                              gizmoAxis = axis;
                              break gizmo_loop;
                            }
                          }
                        }
                      }

                      setGizmo({
                        ...gizmo,
                        active: gizmoAxis !== "",
                        axis: gizmoAxis,
                      });
                    }
                  }
                }}
                type={ScreenSpaceEventType.MOUSE_MOVE}
              />
            </ScreenSpaceEventHandler>

            {/* trackedEntity 用。不要になるかもしれない */}
            <Entity
              ref={gizmoCenterRef}
              name={"gizmoCenter"}
              position={positionCenter}
              orientation={
                new Cesium.ConstantProperty(
                  Cesium.Transforms.headingPitchRollQuaternion(
                    positionCenter,
                    Cesium.HeadingPitchRoll.fromDegrees(
                      offset.heading,
                      offset.pitch,
                      offset.roll
                    )
                  )
                )
              }
            >
              <ModelGraphics
                uri={`/gizmo/terrain.glb`}
                scale={new Cesium.ConstantProperty(100)}
                color={Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0)}
                colorBlendMode={Cesium.ColorBlendMode.REPLACE}
              />
            </Entity>

            <Entity
              ref={gizmoPlaneRef}
              show={gizmo.active}
              name={"gizmoPlane"}
              position={positionCenter}
              orientation={
                new Cesium.ConstantProperty(
                  Cesium.Quaternion.multiply(
                    Cesium.Transforms.headingPitchRollQuaternion(
                      positionCenter,
                      gizmo.mode === "rotate"
                        ? Cesium.HeadingPitchRoll.fromDegrees(
                            offset.heading,
                            offset.pitch,
                            offset.roll
                          )
                        : new Cesium.HeadingPitchRoll()
                    ),
                    Cesium.Quaternion.fromHeadingPitchRoll(
                      Cesium.HeadingPitchRoll.fromDegrees(
                        0,
                        gizmo.axis === "x" ? -90 : 0,
                        gizmo.axis === "y" ? 90 : 0
                      )
                    ),
                    new Cesium.Quaternion()
                  )
                )
              }
            >
              <PlaneGraphics
                dimensions={new Cesium.Cartesian2(1000000, 1000000)}
                plane={
                  new Cesium.Plane(
                    gizmo.mode === "rotate"
                      ? Cesium.Cartesian3.UNIT_Z
                      : Cesium.Cartesian3.UNIT_X,
                    0.0
                  )
                }
                material={Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.01)}
                outline={true}
                outlineColor={Cesium.Color.WHITE}
              />
            </Entity>

            <Entity show={gizmo.dragging} name={"gizmoStart"}>
              <PolylineGraphics
                positions={
                  gizmo.mode === "rotate"
                    ? [positionCenter, gizmo.start]
                    : [
                        Cesium.Cartographic.toCartesian(
                          gizmo.startCartographic
                        ),
                        positionCenter,
                      ]
                }
                width={50}
                arcType={Cesium.ArcType.NONE}
                material={
                  new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
              />
            </Entity>
            <Entity
              show={gizmo.dragging && gizmo.mode === "rotate"}
              name={"gizmoEnd"}
            >
              <PolylineGraphics
                positions={[positionCenter, gizmo.end]}
                width={50}
                arcType={Cesium.ArcType.NONE}
                material={
                  new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
              />
            </Entity>

            <Entity
              ref={gizmoRef.z}
              name={"gizmoZ"}
              position={positionCenter}
              orientation={
                new Cesium.ConstantProperty(
                  Cesium.Transforms.headingPitchRollQuaternion(
                    positionCenter,
                    gizmo.mode === "rotate"
                      ? Cesium.HeadingPitchRoll.fromDegrees(
                          offset.heading,
                          offset.pitch,
                          offset.roll
                        )
                      : new Cesium.HeadingPitchRoll()
                  )
                )
              }
            >
              <ModelGraphics
                uri={`/gizmo/${gizmo.mode}.glb`}
                minimumPixelSize={minimumPixelSize[gizmo.mode]}
                maximumScale={20000}
                color={
                  gizmo.mode === "terrain" ||
                  (gizmo.axis === "z" && gizmo.active)
                    ? Cesium.Color.fromAlpha(Cesium.Color.YELLOW, 0.8)
                    : Cesium.Color.fromAlpha(Cesium.Color.BLUE, 0.8)
                }
                colorBlendMode={Cesium.ColorBlendMode.REPLACE}
              />
            </Entity>

            <Entity
              ref={gizmoRef.y}
              name={"gizmoY"}
              position={positionCenter}
              orientation={
                new Cesium.ConstantProperty(
                  Cesium.Transforms.headingPitchRollQuaternion(
                    positionCenter,
                    gizmo.mode === "rotate"
                      ? Cesium.HeadingPitchRoll.fromDegrees(
                          offset.heading,
                          offset.pitch,
                          offset.roll + 90
                        )
                      : Cesium.HeadingPitchRoll.fromDegrees(0, 0, 90)
                  )
                )
              }
            >
              <ModelGraphics
                uri={`/gizmo/${gizmo.mode}.glb`}
                minimumPixelSize={minimumPixelSize[gizmo.mode]}
                maximumScale={20000}
                color={
                  gizmo.mode === "terrain" ||
                  (gizmo.axis === "y" && gizmo.active)
                    ? Cesium.Color.fromAlpha(Cesium.Color.YELLOW, 0.8)
                    : Cesium.Color.fromAlpha(Cesium.Color.RED, 0.8)
                }
                colorBlendMode={Cesium.ColorBlendMode.REPLACE}
              />
            </Entity>

            <Entity
              ref={gizmoRef.x}
              name={"gizmoX"}
              position={positionCenter}
              orientation={
                new Cesium.ConstantProperty(
                  Cesium.Quaternion.multiply(
                    Cesium.Transforms.headingPitchRollQuaternion(
                      positionCenter,
                      gizmo.mode === "rotate"
                        ? Cesium.HeadingPitchRoll.fromDegrees(
                            offset.heading,
                            offset.pitch,
                            offset.roll
                          )
                        : new Cesium.HeadingPitchRoll()
                    ),
                    Cesium.Quaternion.fromHeadingPitchRoll(
                      Cesium.HeadingPitchRoll.fromDegrees(0, 90, 0)
                    ),
                    new Cesium.Quaternion()
                  )
                )
              }
            >
              <ModelGraphics
                uri={`/gizmo/${gizmo.mode}.glb`}
                minimumPixelSize={minimumPixelSize[gizmo.mode]}
                maximumScale={20000}
                color={
                  gizmo.mode === "terrain" ||
                  (gizmo.axis === "x" && gizmo.active)
                    ? Cesium.Color.fromAlpha(Cesium.Color.YELLOW, 0.8)
                    : Cesium.Color.fromAlpha(Cesium.Color.GREEN, 0.8)
                }
                colorBlendMode={Cesium.ColorBlendMode.REPLACE}
              />
            </Entity>

            {record.type === "3DCapture" && (
              <Cesium3DTileset
                ref={tilesetRef}
                url={`${process.env.REACT_APP_CF_R2_SERVER_URL}/u-${tokenParsed.sub}/a-${record.id}/tileset.json`}
                // url={`${process.env.REACT_APP_RESOURCE_SERVER_URL}/3dtiles/${identity?.fullName}/${props.id}/tileset.json?mode=origin`}

                // colorBlendMode={Cesium.Cesium3DTileColorBlendMode.MIX}

                onReady={(tileset) => {
                  // ハンドルをつくろう。もしかしたら立方体なオブジェクトも
                  boundingSphereCenter = tileset.boundingSphere.center.clone();
                  setPositionCenter(boundingSphereCenter);

                  // console.log('record.offset', record.offset);
                  if (record.offset && Object.keys(record.offset).length > 0) {
                    setOffset({ ...offset, ...record.offset });
                    lookAt();
                  } else {
                    resetPosition();
                  }
                }}
              />
            )}

            {record.type === "PointCloud" && (
              <Cesium3DTileset
                ref={tilesetRef}
                url={`${process.env.REACT_APP_CF_R2_SERVER_URL}/u-${tokenParsed.sub}/a-${record.id}/tileset.json`}
                colorBlendMode={Cesium.Cesium3DTileColorBlendMode.MIX}
                style={
                  new Cesium3DTileStyle({
                    pointSize: pointSize,
                    // color: "color('#FFFFFF', 0.8)"
                  })
                }
                onReady={(tileset) => {
                  // ハンドルをつくろう。もしかしたら立方体なオブジェクトも
                  boundingSphereCenter = tileset.boundingSphere.center.clone();
                  setPositionCenter(boundingSphereCenter);

                  // console.log('record.offset', record.offset);
                  if (record.offset && Object.keys(record.offset).length > 0) {
                    setOffset({ ...offset, ...record.offset });
                    lookAt();
                  } else {
                    resetPosition();
                  }
                }}
              />
            )}

            {record.type === "las" && (
              <Cesium3DTileset
                ref={tilesetRef}
                url={`${process.env.REACT_APP_MINIO_URL}/u-${tokenParsed.sub}/a-${record.id}/3dtiles/tileset.json`}
                // url={`${process.env.REACT_APP_RESOURCE_SERVER_URL}/3dtiles/${identity?.fullName}/${props.id}/tileset.json?mode=origin`}
                colorBlendMode={Cesium.Cesium3DTileColorBlendMode.MIX}
                style={
                  new Cesium3DTileStyle({
                    pointSize: pointSize,
                    // color: "color('#FFFFFF', 0.8)"
                  })
                }
                onReady={(tileset) => {
                  // ハンドルをつくろう。もしかしたら立方体なオブジェクトも
                  boundingSphereCenter = tileset.boundingSphere.center.clone();
                  setPositionCenter(boundingSphereCenter);

                  // console.log('record.offset', record.offset);
                  if (record.offset && Object.keys(record.offset).length > 0) {
                    setOffset({ ...offset, ...record.offset });
                    lookAt();
                  } else {
                    resetPosition();
                  }
                }}
              />
            )}

            {record.type === "gltf" && (
              <Model
                ref={modelRef}
                allowPicking={false}
                id="myModel"
                url={`${process.env.REACT_APP_MINIO_URL}/u-${tokenParsed.sub}/a-${record.id}/model/model.glb`}
                modelMatrix={Cesium.Transforms.headingPitchRollToFixedFrame(
                  Cesium.Cartesian3.fromDegrees(
                    offset.longitude,
                    offset.latitude,
                    offset.height
                  ),
                  Cesium.HeadingPitchRoll.fromDegrees(
                    offset.heading,
                    offset.pitch,
                    offset.roll
                  )
                )}
                onReady={(model) => {
                  if (record.offset) {
                    setOffset({ ...offset, ...record.offset });
                    setTimeout(lookAt);

                    //
                    if (
                      record.offset.longitude === 0 &&
                      record.offset.latitude === 0 &&
                      record.offset.height === 0
                    ) {
                      setDialogOpen(true);
                    }
                  }
                }}
              />
            )}
          </Viewer>
          <CardContent>
            <Grid container>
              <Grid item sm={6}>
                <Box display="flex" justifyContent="flex-start">
                  <ToggleButtonGroup
                    size="small"
                    value={gizmo.mode}
                    exclusive
                    onChange={handleChangeGizmoMode}
                    aria-label="gizmo mode"
                  >
                    <ToggleButton value="move" aria-label="move">
                      Move
                    </ToggleButton>
                    <ToggleButton value="rotate" aria-label="rotate">
                      Rotate
                    </ToggleButton>
                    <ToggleButton value="scale" aria-label="scale">
                      Scale
                    </ToggleButton>
                    <ToggleButton value="terrain" aria-label="click map">
                      Click Map
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <MuButton
                    onClick={lookAt}
                    style={{ margin: "0 8px", color: "rgba(0, 0, 0, 0.54)" }}
                    variant="outlined"
                  >
                    LookAt
                  </MuButton>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <ToggleButtonGroup
                    size="small"
                    value={imagery}
                    exclusive
                    onChange={(
                      event: React.MouseEvent<HTMLElement>,
                      newImagery: string | null
                    ) => {
                      if (newImagery) setImagery(newImagery);
                    }}
                    aria-label="set imagery"
                  >
                    <ToggleButton value="plateau" aria-label="plateau">
                      衛星画像
                    </ToggleButton>
                    <ToggleButton value="gisStd" aria-label="gisStd">
                      地理院標準
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">latitude</Typography>
                    <Slider
                      value={offset.latitude}
                      step={0.000001}
                      min={-90}
                      max={90}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "move" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          latitude: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="latitude"
                      source="offset.latitude"
                      type="number"
                      min="-90"
                      max="90"
                      step="0.000001"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "move" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            latitude: parseFloat(e.target.value),
                          });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">longitude</Typography>
                    <Slider
                      value={offset.longitude}
                      step={0.000001}
                      min={-180}
                      max={180}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "move" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          longitude: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="longitude"
                      source="offset.longitude"
                      type="number"
                      min="-180"
                      max="180"
                      step="0.000001"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "move" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            longitude: parseFloat(e.target.value),
                          });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="caption">height</Typography>
                        <Slider
                          value={offset.height}
                          step={0.1}
                          min={-5000}
                          max={5000}
                          valueLabelDisplay="auto"
                          onFocus={(e) => {
                            setGizmo({ ...gizmo, mode: "move" });
                          }}
                          onChange={(e, value) => {
                            setOffset({
                              ...offset,
                              height: isArray(value) ? value[0] : value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          label="height"
                          source="offset.height"
                          type="number"
                          min="-5000"
                          max="5000"
                          step="0.1"
                          onFocus={(e) => {
                            setGizmo({ ...gizmo, mode: "move" });
                          }}
                          onChange={(e) => {
                            if (!Number.isNaN(parseFloat(e.target.value)))
                              setOffset({
                                ...offset,
                                height: parseFloat(e.target.value),
                              });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">heading</Typography>
                    <Slider
                      value={offset.heading}
                      step={0.1}
                      min={-180}
                      max={180}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          heading: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="heading"
                      source="offset.heading"
                      type="number"
                      min="-180"
                      max="180"
                      step="0.1"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            heading: parseFloat(e.target.value),
                          });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">pitch</Typography>
                    <Slider
                      value={offset.pitch}
                      step={0.1}
                      min={-180}
                      max={180}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          pitch: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="pitch"
                      source="offset.pitch"
                      type="number"
                      min="-180"
                      max="180"
                      step="0.1"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            pitch: parseFloat(e.target.value),
                          });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">roll</Typography>
                    <Slider
                      value={offset.roll}
                      step={0.1}
                      min={-180}
                      max={180}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          roll: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="roll"
                      source="offset.roll"
                      type="number"
                      min="-180"
                      max="180"
                      step="0.1"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "rotate" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            roll: parseFloat(e.target.value),
                          });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {record.type === "las" ||
                (record.type === "PointCloud" && (
                  <Grid item xs={12} md={4}>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        point size (beta)
                      </Typography>
                      <Slider
                        value={pointSize}
                        step={1}
                        min={1}
                        max={10}
                        valueLabelDisplay="auto"
                        // onFocus={(e) => { setGizmo({ ...gizmo, mode: "rotate" }) }}
                        onChange={(e, value) => {
                          setPointSize(isArray(value) ? value[0] : value);
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">scale (alpha)</Typography>
                    <Slider
                      value={offset.scale}
                      step={0.1}
                      min={0.1}
                      max={10}
                      valueLabelDisplay="auto"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "scale" });
                      }}
                      onChange={(e, value) => {
                        setOffset({
                          ...offset,
                          scale: isArray(value) ? value[0] : value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label="scale"
                      source="offset.scale"
                      type="number"
                      min="0.000001"
                      max="10"
                      step="0.1"
                      onFocus={(e) => {
                        setGizmo({ ...gizmo, mode: "scale" });
                      }}
                      onChange={(e) => {
                        if (!Number.isNaN(parseFloat(e.target.value)))
                          setOffset({
                            ...offset,
                            scale: parseFloat(e.target.value),
                          });
                      }}
                    />{" "}
                  </Grid>
                  <Typography variant="caption">scale は要望により急遽用意したため暫定対応です。3D地図上のハンドルでの調整は非対応です。</Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-start">
                  <Tooltip
                    arrow
                    title="ブラウザの位置情報から現在地に位置づけます"
                  >
                    <TooltipWrapper>
                      <MuButton
                        style={{
                          margin: "0 8px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                        variant="outlined"
                        onClick={currentPosition}
                      >
                        現在地を設定
                      </MuButton>
                    </TooltipWrapper>
                  </Tooltip>
                  <Tooltip
                    arrow
                    title="日本測地系などで楕円体高ではなく標高基準の点群などを変換された場合は点群が約40mほど地下に表示されます。その場合は [ジオイド高を加算] してみてください"
                  >
                    <TooltipWrapper>
                      <MuButton
                        style={{
                          margin: "0 8px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                        variant="outlined"
                        onClick={addGeoidHeight}
                      >
                        ジオイド高を加算
                      </MuButton>
                    </TooltipWrapper>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Box sx={{ width: { xs: "300px" }, marginRight: "1em" }}>
                    <Labeled label="参照 PLATEAU (alpha)" fullWidth>
                      <AutocompleteInput
                        source="plateau_id" // dummy
                        choices={plateauCatalog}
                        label={false}
                        onChange={(e: any) => {
                          const m = plateauCatalog.find((n) => n.id == e);
                          ref.current?.cesiumElement?.scene.primitives.add(
                            new CesiumCesium3DTileset({
                              url: m.url,
                            })
                          );
                        }}
                      />
                    </Labeled>
                  </Box>
                  <Box sx={{ width: { xs: "300px" } }}>
                    <Labeled label="参照 Asset (alpha)" fullWidth>
                      <ReferenceInput
                        source="asset_id" // dummy
                        reference="assets"
                        label=""
                        // validate={[required()]}
                        enableGetChoices={(filters) =>
                          filters.q && filters.q.length >= 2
                        }
                        filterToQuery={(q) => ({ name: q })}
                      >
                        <AutocompleteInput
                          optionText="name"
                          onChange={(e: any) => {
                            console.log(e);
                            ref.current?.cesiumElement?.scene.primitives.add(
                              new CesiumCesium3DTileset({
                                url: `${process.env.REACT_APP_CF_R2_SERVER_URL}/u-${tokenParsed.sub}/a-${e}/tileset.wrap.json`,
                              })
                            );
                          }}
                        />
                      </ReferenceInput>
                    </Labeled>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            handleDialogClose(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"位置情報がみあたりません"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              読み込まれたデータに、位置情報がみあたりません。
              <br />
              初期値として現在地を設定しますか？
              <br />
              （セットしない場合は座標０の地球の中心となり真っ黒な画面になる場合があります）
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuButton
              onClick={() => {
                handleDialogClose(true);
              }}
              color="primary"
            >
              はい
            </MuButton>
            <MuButton
              onClick={() => {
                handleDialogClose(false);
              }}
              color="primary"
            >
              いいえ
            </MuButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default Adjust;
